// utils/fetchWithToken.ts
import type { FetchOptions } from "ofetch";
import { useFirebaseAuth } from "~/composables/useFirebaseAuth";
import { useAuthErrorHandler } from "~/composables/useAuthErrorHandler";

export async function $fetchWithToken<T>(
    url: string,
    options: FetchOptions = {}
): Promise<T> {
    try {
        const { getFirebaseIdToken } = useFirebaseAuth();
        const { handleAuthError } = useAuthErrorHandler();

        // Get token with retry mechanism
        let token;

        try {
            token = await getFirebaseIdToken();
        } catch (tokenError: any) {
            console.error("Failed to get Firebase token:", tokenError);

            // Check if this is a token expiry issue
            if (
                tokenError.code === "auth/id-token-expired" ||
                tokenError.message?.includes("token-expired") ||
                tokenError.message?.includes("Firebase ID token has expired")
            ) {
                console.log("Token expired, trying with force refresh");

                try {
                    // Try one more time with force refresh
                    token = await getFirebaseIdToken(true);
                } catch (refreshError) {
                    // If refresh also fails, show auth error dialog
                    handleAuthError(
                        "Your session has expired. Please sign in again."
                    );

                    throw createError({
                        statusCode: 401,
                        message: "Authentication failed - session expired",
                        cause: refreshError,
                    });
                }
            } else {
                // For other token errors
                throw createError({
                    statusCode: 401,
                    message:
                        "Authentication failed - unable to retrieve valid token",
                    cause: tokenError,
                });
            }
        }

        const apiUrl =
            url.includes("/api/") || url.includes("://") ? url : `/api${url}`;

        // Don't set Content-Type for FormData - browser will set it automatically with boundary
        const headers: Record<string, string | undefined> = {
            ...options?.headers,
            Authorization: token ? `Bearer ${token}` : undefined,
            idToken: token, // Keep legacy header
        };

        // Only add Content-Type if not FormData
        if (!(options.body instanceof FormData)) {
            headers["Content-Type"] = "application/json";
        }

        const optionsWithToken: FetchOptions = {
            ...options,
            headers,
            onResponseError({ response }) {
                const error = response._data?.error || "An error occurred";
                const statusCode = response.status;

                // Check for auth-related errors in the API response
                if (statusCode === 401 || statusCode === 403) {
                    // Try to determine if it's a token expiry issue
                    const isExpiredToken =
                        (typeof error === "string" &&
                            (error.includes("expired") ||
                                error.includes("token"))) ||
                        (response._data?.message &&
                            typeof response._data.message === "string" &&
                            (response._data.message.includes("expired") ||
                                response._data.message.includes("token")));

                    if (isExpiredToken) {
                        // Show auth error dialog with current path for redirect
                        handleAuthError(
                            "Your session has expired. Please sign in again.",
                            window.location.pathname + window.location.search
                        );
                    }
                }

                throw createError({
                    statusCode: response.status,
                    message: error,
                    data: response._data,
                });
            },
        };

        return $fetch<T>(apiUrl, optionsWithToken);
    } catch (error: any) {
        // Log and rethrow the error
        console.error("Error in $fetchWithToken:", {
            url,
            error: error.message,
            status: error.statusCode,
        });
        throw error;
    }
}
