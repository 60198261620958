import { defineStore } from "pinia";
import { ref, computed } from "vue";

// Define types with more specific information
export interface PartnerAddress {
    street: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
}

export interface NotificationPreferences {
    email: boolean;
    sms: boolean;
}

export interface PartnerSettings {
    notification_preferences: NotificationPreferences;
    default_claim_settings?: Record<string, any>;
}

export type PartnerType =
    | "hospital"
    | "clinic"
    | "physician_office"
    | "laboratory";
export type PartnerStatus = "active" | "suspended" | "pending";

type DocumentType = "soap_note" | "patient_summary";

interface PartnerScribeSettings {
    document_defaults: DocumentType[];
    language: string;
}

export interface Partner {
    id: string;
    name: string;
    short_name: string;
    slug: string;
    type: PartnerType;
    address: PartnerAddress;
    status: PartnerStatus;
    scribe_settings: PartnerScribeSettings;
    created_at: string;
    updated_at: string;
}

export interface PartnerState {
    partner: Partner | null;
    isLoading: boolean;
    error: string | null;
}

/**
 * Partner Store
 *
 * Manages partner organization data, including loading, updating,
 * and providing derived information.
 */
export const usePartnerStore = defineStore(
    "partner",
    () => {
        // State
        const partner = ref<Partner | null>(null);
        const isLoading = ref(false);
        const error = ref<string | null>(null);

        const accessStore = useAccessStore();
        const { partnerId: accessPartnerId } = storeToRefs(accessStore);

        // Getters
        const isPartnerLoaded = computed(() => !!partner.value);
        const partnerId = computed(() => partner.value?.id);
        const partnerName = computed(() => partner.value?.name);
        const isActive = computed(() => partner.value?.status === "active");
        const acceptedInsuranceNetworks = computed(
            () => partner.value?.insurance_networks || []
        );

        // Helper function to handle API errors
        const handleApiError = (e: any): never => {
            const errorMsg =
                e.message || "An error occurred with the partner data";
            error.value = errorMsg;
            console.error("Partner store error:", e);
            throw new Error(errorMsg);
        };

        // /**
        //  * Load partner details
        //  * If a partner is already loaded, this will return immediately
        //  * unless force=true is specified
        //  */
        // async function loadPartner(force = false) {
        //     if (partner.value && !force) return partner.value;

        //     isLoading.value = true;
        //     error.value = null;

        //     try {
        //         const { data, error: fetchError } =
        //             await $fetchWithToken<Partner>(
        //                 `/api/v4/partner/${accessPartnerId.value}`
        //             );

        //         if (fetchError.value) throw fetchError.value;
        //         if (!data.value) throw new Error("No partner data received");

        //         partner.value = data.value;
        //         accessPartnerId.value = data.value.id;
        //         return partner.value;
        //     } catch (e: any) {
        //         error.value = e.message || "Failed to load partner";

        //         // Only logout on specific errors, not all errors
        //         if (e.statusCode === 401) {
        //             const auth = useAuthStore();
        //             auth.logout();
        //         }

        //         throw e;
        //     } finally {
        //         isLoading.value = false;
        //     }
        // }

        /**
         * Update partner details
         */
        async function updatePartner(updates: Partial<Partner>) {
            isLoading.value = true;
            error.value = null;

            try {
                const data = await $fetchWithToken<Partner>(
                    `/api/v4/partner/${accessPartnerId.value}`,
                    {
                        method: "POST",
                        body: updates,
                    }
                );

                if (!data) throw new Error("No updated partner data received");

                partner.value = data;
                return partner.value;
            } catch (e: any) {
                error.value = e.message || "Failed to update partner";
                throw e;
            } finally {
                isLoading.value = false;
            }
        }

        /**
         * Clear partner data from the store
         */
        function clearPartner() {
            partner.value = null;
            error.value = null;
            accessPartnerId.value = null;
        }

        /**
         * Reset the store to its initial state
         */
        function $reset() {
            clearPartner();
            isLoading.value = false;
        }

        return {
            // State
            partner,
            isLoading,
            error,
            accessPartnerId,

            // Getters
            isPartnerLoaded,
            partnerId,
            partnerName,
            isActive,
            acceptedInsuranceNetworks,

            // Actions
            // loadPartner,
            updatePartner,
            clearPartner,
            $reset,
        };
    }
    // {
    //     persist: {
    //         paths: ["partner", "accessPartnerId"],
    //     },
    // }
);
