// composables/useFirebaseAuth.ts - Enhanced with integrated user synchronization
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithCustomToken,
    onAuthStateChanged,
    onIdTokenChanged,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    verifyPasswordResetCode,
    type Auth,
    type User,
} from "firebase/auth";
import { ofetch } from "ofetch";

export function useFirebaseAuth() {
    const { $firebaseAuth } = useNuxtApp();
    const auth = $firebaseAuth as Auth;

    // Store references
    const identityStore = useIdentityStore();
    const accessStore = useAccessStore();
    const sessionStore = useSessionStore();

    // Current user shorthand (useful for components)
    const currentUser = computed(() => auth.currentUser);

    // Cookie for SSR support
    const userCookie = useCookie("firebase-user", {
        maxAge: 60 * 60 * 24 * 7, // 1 week
        sameSite: "lax",
    });

    // Token refresh management
    const tokenRefreshTimeout = ref<NodeJS.Timeout | null>(null);
    const tokenExpiryBuffer = 5 * 60 * 1000; // 5 minutes before expiry

    // Direct reactive refs for more reliable state tracking
    const claimsLoadedState = ref(false);
    const syncInitializedState = ref(false);

    // Claims loading state management
    const claimsState = reactive({
        loaded: false,
        loading: false,
        error: null as Error | null,
        promiseResolver: null as ((value: boolean) => void) | null,
    });

    // User synchronization state
    const syncState = reactive({
        initialized: false,
        loading: false,
        error: null as Error | null,
        lastSyncTime: null as Date | null,
    });

    // Initialize Firebase Auth with integrated synchronization
    const initAuth = async (): Promise<void> => {
        // Set stores to initializing state
        if (!identityStore.isInitializing) {
            identityStore.isInitializing = true;
        }

        claimsState.loaded = false;
        claimsLoadedState.value = false;
        syncState.initialized = false;
        syncInitializedState.value = false;

        return new Promise<void>((resolve, reject) => {
            try {
                console.log("[useFirebaseAuth] Starting auth initialization");

                // Listen for auth state changes
                const unsubscribeAuthState = onAuthStateChanged(
                    auth,
                    async (user) => {
                        try {
                            console.log(
                                "[useFirebaseAuth] Auth state changed:",
                                user ? `User ${user.uid}` : "No user"
                            );

                            // Update identity store
                            identityStore.setFirebaseUser(user);

                            // Update cookie for SSR
                            if (user) {
                                userCookie.value = user.uid;
                                // Force a token refresh to ensure we get the latest claims
                                await refreshIdToken();
                            } else {
                                userCookie.value = null;

                                // Clear stores on logout
                                accessStore.reset();
                                sessionStore.reset();

                                // Reset claims and sync state
                                claimsState.loaded = false;
                                claimsLoadedState.value = false;
                                syncState.initialized = false;
                                syncInitializedState.value = false;
                                syncState.loading = false;
                                syncState.lastSyncTime = null;

                                // Resolve claims loading if there was a pending promise
                                if (claimsState.promiseResolver) {
                                    claimsState.promiseResolver(false);
                                    claimsState.promiseResolver = null;
                                }
                            }

                            resolve();
                        } catch (error) {
                            console.error(
                                "[useFirebaseAuth] Auth state change error:",
                                error
                            );
                            identityStore.isInitializing = false;
                            reject(error);
                        }
                    }
                );

                // Listen for ID token changes to update permissions
                const unsubscribeTokenChanged = onIdTokenChanged(
                    auth,
                    async (user) => {
                        if (user) {
                            try {
                                console.log(
                                    "[useFirebaseAuth] Token changed for user:",
                                    user.uid
                                );

                                // Get the full token with claims
                                const tokenResult =
                                    await user.getIdTokenResult();

                                // Update the access store with claims
                                accessStore.setClaims(tokenResult.claims);
                                console.log(
                                    "[useFirebaseAuth] Updated claims:",
                                    tokenResult.claims
                                );

                                // Setup token refresh
                                setupTokenRefresh(user);

                                // Update session management
                                sessionStore.setTokenExpiration(
                                    tokenResult.expirationTime
                                );

                                // Mark claims as loaded and resolve any pending promises
                                claimsState.loaded = true;
                                claimsLoadedState.value = true; // Use the direct ref for reactivity
                                console.log(
                                    "[useFirebaseAuth] Claims loaded state explicitly set to true"
                                );
                                claimsState.loading = false;

                                if (claimsState.promiseResolver) {
                                    claimsState.promiseResolver(true);
                                    claimsState.promiseResolver = null;
                                }

                                // Once we have a valid token with claims, synchronize user data
                                // Only sync if not already synced and not currently syncing
                                if (
                                    !syncState.initialized &&
                                    !syncInitializedState.value &&
                                    !syncState.loading
                                ) {
                                    console.log(
                                        "[useFirebaseAuth] Claims loaded, initiating user synchronization"
                                    );
                                    await synchronizeUserData(user);
                                }
                            } catch (error) {
                                console.error(
                                    "[useFirebaseAuth] Token processing error:",
                                    error
                                );
                                claimsState.error = error;
                                claimsState.loading = false;

                                if (claimsState.promiseResolver) {
                                    claimsState.promiseResolver(false);
                                    claimsState.promiseResolver = null;
                                }
                            }
                        }
                    }
                );

                // Clean up event listeners when component unmounts
                onBeforeUnmount(() => {
                    // Unsubscribe from Firebase listeners
                    unsubscribeAuthState();
                    unsubscribeTokenChanged();

                    // Clear token refresh timeout
                    if (tokenRefreshTimeout.value) {
                        clearTimeout(tokenRefreshTimeout.value);
                        tokenRefreshTimeout.value = null;
                    }
                });
            } catch (error) {
                console.error(
                    "[useFirebaseAuth] Auth initialization error:",
                    error
                );
                identityStore.isInitializing = false;
                reject(error);
            }
        });
    };

    // Force a refresh of the ID token
    const refreshIdToken = async (): Promise<string | null> => {
        if (!auth.currentUser) return null;

        try {
            return await auth.currentUser.getIdToken(true);
        } catch (error) {
            console.error("[useFirebaseAuth] Error refreshing token:", error);
            return null;
        }
    };

    // Wait for user claims to be loaded - returns a Promise
    const waitForClaims = async (maxWaitMs = 5000): Promise<boolean> => {
        // If claims are already loaded, return immediately
        if (claimsState.loaded || claimsLoadedState.value) return true;

        // If no user is signed in, claims won't load
        if (!auth.currentUser) return false;

        // If we're already loading claims, wait for the existing process
        if (claimsState.loading) {
            return new Promise<boolean>((resolve) => {
                // Store the resolver to be called when claims are loaded
                claimsState.promiseResolver = resolve;

                // Set a max wait timeout as a fallback
                setTimeout(() => {
                    if (claimsState.promiseResolver === resolve) {
                        console.warn(
                            "[useFirebaseAuth] Timed out waiting for claims"
                        );
                        claimsState.promiseResolver = null;
                        resolve(claimsState.loaded || claimsLoadedState.value);
                    }
                }, maxWaitMs);
            });
        }

        // Start a new claims loading process
        claimsState.loading = true;

        return new Promise<boolean>((resolve) => {
            // Store the resolver
            claimsState.promiseResolver = resolve;

            // Force a token refresh to trigger the onIdTokenChanged listener
            refreshIdToken().catch(() => {
                claimsState.loading = false;
                claimsState.promiseResolver = null;
                resolve(false);
            });

            // Set a max wait timeout as a fallback
            setTimeout(() => {
                if (claimsState.promiseResolver === resolve) {
                    console.warn(
                        "[useFirebaseAuth] Timed out waiting for claims"
                    );
                    claimsState.loading = false;
                    claimsState.promiseResolver = null;
                    resolve(claimsState.loaded || claimsLoadedState.value);
                }
            }, maxWaitMs);
        });
    };

    // Internal function to synchronize user data with backend
    const synchronizeUserData = async (user: User): Promise<any> => {
        if (syncState.loading) {
            console.log(
                "[useFirebaseAuth] Synchronization already in progress"
            );
            return;
        }

        // Skip if we've already synchronized
        if (
            (syncState.initialized || syncInitializedState.value) &&
            syncState.lastSyncTime &&
            !process.dev
        ) {
            console.log(
                "[useFirebaseAuth] User already synchronized, skipping"
            );
            return;
        }

        syncState.loading = true;
        syncState.error = null;

        try {
            console.log("[useFirebaseAuth] Starting user synchronization");
            const token = await user.getIdToken();

            // Call your backend API to get user profile and related data
            const data = await $fetch("/api/v4/authenticate", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    idToken: token,
                },
            });

            console.log("[useFirebaseAuth] Synchronization successful:", {
                hasAccount: !!data.account,
                hasPartner: !!data.partner,
            });

            // Update partner store if data exists
            if (data.partner) {
                const partnerStore = usePartnerStore();
                partnerStore.$patch({ partner: data.partner });
            }

            // Update account store if data exists
            if (data.account) {
                const accountStore = useAccountStore();
                accountStore.$patch({ account: data.account });
            }

            // Mark as initialized and record sync time - use both reactive state methods
            syncState.initialized = true;
            syncInitializedState.value = true; // Direct reactive ref
            syncState.lastSyncTime = new Date();
            console.log("[useFirebaseAuth] SYNC INITIALIZED SET TO TRUE");

            return data;
        } catch (error) {
            console.error(
                "[useFirebaseAuth] User synchronization error:",
                error
            );
            syncState.error = error;
            throw error;
        } finally {
            syncState.loading = false;
        }
    };

    // Setup token refresh
    const setupTokenRefresh = async (user: User) => {
        // Clear any existing timeout
        if (tokenRefreshTimeout.value) {
            clearTimeout(tokenRefreshTimeout.value);
            tokenRefreshTimeout.value = null;
        }

        try {
            // Get token result to check expiry time
            const tokenResult = await user.getIdTokenResult();
            const expiryTime = new Date(tokenResult.expirationTime).getTime();
            const now = Date.now();

            // Calculate time until refresh (expiry minus buffer)
            const timeToRefresh = Math.max(
                0,
                expiryTime - now - tokenExpiryBuffer
            );

            console.log(
                `[useFirebaseAuth] Token will refresh in ${Math.floor(
                    timeToRefresh / 1000
                )} seconds`
            );

            // Set timer to refresh token before it expires
            tokenRefreshTimeout.value = setTimeout(() => {
                console.log("[useFirebaseAuth] Proactively refreshing token");
                user.getIdToken(true)
                    .then(() => {
                        // After successful refresh, setup the next refresh
                        if (auth.currentUser) {
                            setupTokenRefresh(auth.currentUser);
                        }
                    })
                    .catch((err) => {
                        console.error(
                            "[useFirebaseAuth] Token refresh failed:",
                            err
                        );
                        // If refresh fails, try again in a minute
                        tokenRefreshTimeout.value = setTimeout(() => {
                            if (auth.currentUser) {
                                setupTokenRefresh(auth.currentUser);
                            }
                        }, 60 * 1000);
                    });
            }, timeToRefresh);
        } catch (error) {
            console.error(
                "[useFirebaseAuth] Failed to setup token refresh:",
                error
            );
        }
    };

    // Public method to get the ID token for API calls
    const getFirebaseIdToken = async (forceRefresh = false) => {
        try {
            if (!auth.currentUser) {
                throw new Error("No authenticated user");
            }

            const token = await auth.currentUser.getIdToken(forceRefresh);

            // If forcing a refresh, we should update our claims state
            if (forceRefresh && !claimsState.loaded) {
                claimsState.loaded = true;
                claimsLoadedState.value = true;
            }

            // Setup proactive token refresh after getting a token
            setupTokenRefresh(auth.currentUser);

            return token;
        } catch (error) {
            console.error("[useFirebaseAuth] Get ID token error:", error);

            // Check for token expiry errors
            if (
                error.code === "auth/id-token-expired" ||
                error.message?.includes("token-expired") ||
                error.message?.includes("Firebase ID token has expired")
            ) {
                console.log(
                    "[useFirebaseAuth] Token expired, attempting force refresh"
                );

                // Emit an event that can be caught by the global handler
                const authErrorBus = useEventBus("auth-error");
                authErrorBus.emit("token-expired");

                // Try one more time with force refresh
                if (!forceRefresh) {
                    return getFirebaseIdToken(true);
                }
            }

            throw error;
        }
    };

    // Debug helper function
    const debugAuthState = () => {
        return {
            claims: {
                loaded: claimsState.loaded,
                loadedRef: claimsLoadedState.value,
                loading: claimsState.loading,
            },
            sync: {
                initialized: syncState.initialized,
                initializedRef: syncInitializedState.value,
                loading: syncState.loading,
            },
            user: {
                authenticated: !!auth.currentUser,
                uid: auth.currentUser?.uid,
            },
        };
    };

    // Public method to force a new synchronization
    const forceSynchronize = async (): Promise<any> => {
        if (!auth.currentUser) {
            throw new Error("No authenticated user");
        }

        // Reset sync state to force a new sync
        syncState.initialized = false;
        syncInitializedState.value = false;
        return synchronizeUserData(auth.currentUser);
    };

    // Sign in with email and password
    const signInWithEmail = async (email: string, password: string) => {
        try {
            const result = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );

            // Wait for claims to be loaded before continuing
            await waitForClaims();

            return result.user;
        } catch (error) {
            // Friendly error messages
            const errorCode = error.code || "unknown";
            let errorMessage = "Failed to sign in";

            if (
                errorCode === "auth/user-not-found" ||
                errorCode === "auth/wrong-password"
            ) {
                errorMessage = "Invalid email or password. Please try again.";
            } else if (errorCode === "auth/too-many-requests") {
                errorMessage =
                    "Too many failed login attempts. Please try again later.";
            }

            throw createError({
                statusCode: 401,
                statusMessage: errorMessage,
                cause: error,
            });
        }
    };

    // Sign in with custom token
    const signInWithToken = async (token: string) => {
        try {
            const result = await signInWithCustomToken(auth, token);

            // Wait for claims to be loaded before continuing
            await waitForClaims();

            return result.user;
        } catch (error) {
            // Friendly error messages
            const errorCode = error.code || "unknown";
            let errorMessage = "Failed to authenticate with token";

            if (errorCode === "auth/invalid-custom-token") {
                errorMessage =
                    "The authentication token is invalid or expired.";
            } else if (errorCode === "auth/custom-token-mismatch") {
                errorMessage = "The token does not match this application.";
            }

            console.error("Custom token sign-in error:", errorCode, error);

            throw createError({
                statusCode: 401,
                statusMessage: errorMessage,
                cause: error,
            });
        }
    };

    // Create new user
    const createUser = async (email: string, password: string) => {
        try {
            const result = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );

            // Wait for claims to be loaded
            await waitForClaims();

            return result.user;
        } catch (error) {
            // Friendly error messages
            const errorCode = error.code || "unknown";
            let errorMessage = "Failed to create account";

            if (errorCode === "auth/email-already-in-use") {
                errorMessage =
                    "This email is already registered. Please sign in instead.";
            } else if (errorCode === "auth/weak-password") {
                errorMessage =
                    "Password is too weak. Please choose a stronger password.";
            }

            throw createError({
                statusCode: 400,
                statusMessage: errorMessage,
                cause: error,
            });
        }
    };

    // Sign out user
    const signOutUser = async () => {
        try {
            // Clear cookie first
            userCookie.value = null;

            // Reset stores
            identityStore.reset();
            accessStore.reset();
            sessionStore.reset();

            // Reset claims state
            claimsState.loaded = false;
            claimsLoadedState.value = false;
            claimsState.loading = false;
            claimsState.error = null;

            if (claimsState.promiseResolver) {
                claimsState.promiseResolver(false);
                claimsState.promiseResolver = null;
            }

            // Reset sync state
            syncState.initialized = false;
            syncInitializedState.value = false;
            syncState.loading = false;
            syncState.error = null;
            syncState.lastSyncTime = null;

            // Sign out from Firebase
            await signOut(auth);

            // Navigate to login page
            await navigateTo("/");

            return true;
        } catch (error) {
            console.error("Sign out error:", error);
            throw error;
        }
    };

    const sendPasswordReset = async (email: string) => {
        console.log("[useFirebaseAuth] Sending password reset email");
        try {
            // Call our server endpoint without passing origin
            const response = await ofetch("/api/auth/password-reset", {
                method: "POST",
                body: { email },
            });

            console.log("Password reset email sent successfully: ", response);
            return true;
        } catch (error) {
            console.error("Password reset request error:", error);

            // Handle the error
            let errorMessage = "Failed to send password reset email";

            if (error.response?.status === 400) {
                errorMessage =
                    error.response.statusMessage || "Invalid request";
            } else if (error.response?.status === 429) {
                errorMessage = "Too many requests. Please try again later.";
            }

            throw createError({
                statusCode: error.response?.status || 500,
                statusMessage: errorMessage,
                cause: error,
            });
        }
    };

    // Verify password reset code and get associated email
    const verifyResetCode = async (code: string) => {
        try {
            return await verifyPasswordResetCode(auth, code);
        } catch (error) {
            const errorCode = error.code || "unknown";
            let errorMessage = "Invalid or expired reset link";

            if (errorCode === "auth/expired-action-code") {
                errorMessage =
                    "This reset link has expired. Please request a new one.";
            } else if (errorCode === "auth/invalid-action-code") {
                errorMessage =
                    "This reset link is invalid. Please request a new one.";
            }

            throw createError({
                statusCode: 400,
                statusMessage: errorMessage,
                cause: error,
            });
        }
    };

    // Complete password reset with new password
    const confirmReset = async (code: string, newPassword: string) => {
        try {
            await confirmPasswordReset(auth, code, newPassword);
            return true;
        } catch (error) {
            const errorCode = error.code || "unknown";
            let errorMessage = "Failed to reset password";

            if (errorCode === "auth/expired-action-code") {
                errorMessage =
                    "This reset link has expired. Please request a new one.";
            } else if (errorCode === "auth/invalid-action-code") {
                errorMessage =
                    "This reset link is invalid. Please request a new one.";
            } else if (errorCode === "auth/weak-password") {
                errorMessage = "Please choose a stronger password.";
            }

            throw createError({
                statusCode: 400,
                statusMessage: errorMessage,
                cause: error,
            });
        }
    };

    return {
        // Core auth functions
        initAuth,
        signInWithEmail,
        signInWithToken,
        createUser,
        signOutUser,
        sendPasswordReset,
        verifyResetCode,
        confirmReset,
        getFirebaseIdToken,
        waitForClaims,
        refreshIdToken,
        forceSynchronize,
        debugAuthState,

        // Claims state - now with both reactive approaches
        claimsState: readonly(claimsState),
        claimsLoaded: computed(
            () => claimsState.loaded || claimsLoadedState.value
        ),
        claimsLoadedDirect: claimsLoadedState,
        claimsLoading: computed(() => claimsState.loading),
        claimsError: computed(() => claimsState.error),

        // Sync state - now with both reactive approaches
        syncInitialized: computed(
            () => syncState.initialized || syncInitializedState.value
        ),
        syncInitializedDirect: syncInitializedState,
        syncLoading: computed(() => syncState.loading),
        syncError: computed(() => syncState.error),
        lastSyncTime: computed(() => syncState.lastSyncTime),

        // Auth state
        currentUser,

        // Token refresh
        setupTokenRefresh,
    };
}
