// stores/auth/identity.ts - Who the user is
import { defineStore, skipHydrate } from "pinia";
import type { User } from "firebase/auth";

export interface UserProfile {
    id: string;
    displayName?: string;
    email: string;
    photoURL?: string;
    createdAt?: string;
    lastLoginAt?: string;
    // Add other user profile fields as needed
}

export const useIdentityStore = defineStore("identity", () => {
    // Core state
    const isInitializing = ref(true);
    const firebaseUser = ref<User | null>(null);
    const userProfile = ref<UserProfile | null>(null);
    const error = ref<Error | null>(null);

    // Track first login for synchronization purposes
    const isFirstLogin = ref(true);

    // Computed properties
    const isAuthenticated = computed(() => !!firebaseUser.value);
    const uid = computed(() => firebaseUser.value?.uid || null);
    const email = computed(
        () => firebaseUser.value?.email || userProfile.value?.email || null
    );
    const displayName = computed(
        () =>
            userProfile.value?.displayName ||
            firebaseUser.value?.displayName ||
            email.value ||
            "User"
    );

    // Set Firebase user
    function setFirebaseUser(user: User | null) {
        const previousUser = firebaseUser.value?.uid;
        firebaseUser.value = user;

        // If user is different or changing from null, set first login flag
        if (user && (!previousUser || previousUser !== user.uid)) {
            isFirstLogin.value = true;
        }

        isInitializing.value = false;
    }

    // Set user profile from backend
    function setUserProfile(profile: UserProfile | null) {
        userProfile.value = profile;

        // Once we've loaded the profile, we're no longer in first login state
        if (profile) {
            isFirstLogin.value = false;
        }
    }

    // Clear user on logout
    function clearIdentity() {
        firebaseUser.value = null;
        userProfile.value = null;
        isFirstLogin.value = true;
    }

    // Reset entire store
    function reset() {
        isInitializing.value = true;
        firebaseUser.value = null;
        userProfile.value = null;
        error.value = null;
        isFirstLogin.value = true;
    }

    return {
        // State
        isInitializing,
        firebaseUser,
        userProfile,
        error: skipHydrate(error),
        isFirstLogin,

        // Computed
        isAuthenticated,
        uid,
        email,
        displayName,

        // Methods
        setFirebaseUser,
        setUserProfile,
        clearIdentity,
        reset,
    };
});
