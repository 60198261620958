<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
      <UNotifications>
        <template #title="{ title }">
          <span v-html="title" />
        </template>
        <template #description="{ description }">
          <span v-html="description" />
        </template>
      </UNotifications>
      <UModals />
      <USlideovers />
    </NuxtLayout>
    <!-- Add Auth Error Dialog -->
    <AuthErrorHandler />
  </div>
</template>

<script setup>
import { useFirebaseAuth } from '~/composables/useFirebaseAuth'

const colorMode = useColorMode()
colorMode.preference = 'light';

const { locale } = useI18n()
const { initAuth, claimsLoaded } = useFirebaseAuth()

// Initialize the auth error handler
const { setupGlobalErrorInterceptor } = useAuthErrorHandler()

// Track initial auth setup
const initialAuthSetup = ref(false)

// Initialize auth state - this now handles the entire auth flow
onMounted(async () => {
  if (initialAuthSetup.value) return;

  try {
    console.log('[App] Initializing authentication...');
    await initAuth();
    initialAuthSetup.value = true;
    console.log('[App] Authentication initialization complete');
  } catch (error) {
    console.error('[App] Failed to initialize auth:', error);
  }
});

const htmlAttrs = computed(() => {
  return {
    lang: locale.value,
  }
})

useHead({
  htmlAttrs: htmlAttrs,
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" },
    {
      name: "title",
      content:
        "NomadRx | Overseas healthcare for expats",
    },
    {
      name: "description",
      content:
        "Find overseas doctors that accept your insurance",
    },
    { property: "og:type", content: "website" },
    {
      property: "og:title",
      content: "NomadRx | Overseas healthcare for expats",
    },
    {
      property: "og:description",
      content:
        "Find overseas doctors that accept your insurance.",
    },
    {
      property: "og:image",
      content: "https://www.nomadrx.co/nomadrx_og.png",
    },
    {
      property: "og:url",
      content: "https://www.nomadrx.co",
    },
    {
      name: "facebook-domain-verification",
      content: "t0bh2oq8gjg4ji9x16cjjfenke40pj",
    },
  ],
  link: [
    {
      rel: "canonical",
      href: "https://www.nomadrx.co/",
    },
  ]
});

// Setup error interceptor
onMounted(() => {
  setupGlobalErrorInterceptor()
})
</script>