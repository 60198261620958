// types/auth.ts - Basic type definitions
export type UserRole = "admin" | "client" | "partner";

export type AppFeature =
    | "dashboard"
    | "user_management"
    | "reporting"
    | "billing"
    | "document_management"
    | "settings";

// Map roles to features - you can move this to a separate constants file
export const ROLE_FEATURES: Record<UserRole, AppFeature[]> = {
    admin: [
        "dashboard",
        "user_management",
        "reporting",
        "billing",
        "document_management",
        "settings",
    ],
    client: ["dashboard", "reporting", "document_management"],
    partner: ["dashboard", "reporting", "document_management", "billing"],
};
