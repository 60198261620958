import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/workspace/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_BqLkNUef68 from "/workspace/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/workspace/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/workspace/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/workspace/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/workspace/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_i8AMfKeYnY from "/workspace/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_WLsn00x1qh from "/workspace/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/workspace/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/workspace/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import scrollbars_client_Eg4DPJPcY6 from "/workspace/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/workspace/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import presets_1aypKNZ222 from "/workspace/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/workspace/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import _001_sentry_client_xpLkvhnHhp from "/workspace/plugins/001.sentry.client.ts";
import _090_sentryErrors_ejWnMl6Lx9 from "/workspace/plugins/090.sentryErrors.ts";
import analytics_client_mZYnNRQBH8 from "/workspace/plugins/analytics.client.js";
import contentful_bOzdIRmqaX from "/workspace/plugins/contentful.js";
import firebaseAuth_client_OP6eaV11Gv from "/workspace/plugins/firebaseAuth.client.ts";
import hotjar_client_HJG8no80GI from "/workspace/plugins/hotjar.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_BqLkNUef68,
  plugin_8SbxDRbG6Y,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_i8AMfKeYnY,
  plugin_WLsn00x1qh,
  plugin_tbFNToZNim,
  plugin_t2GMTTFnMT,
  scrollbars_client_Eg4DPJPcY6,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  _001_sentry_client_xpLkvhnHhp,
  _090_sentryErrors_ejWnMl6Lx9,
  analytics_client_mZYnNRQBH8,
  contentful_bOzdIRmqaX,
  firebaseAuth_client_OP6eaV11Gv,
  hotjar_client_HJG8no80GI
]