// composables/useAuthErrorHandler.ts
import { useFirebaseAuth } from "./useFirebaseAuth";
import { ofetch } from "ofetch";

export function useAuthErrorHandler() {
    const authErrorState = useState("auth-error-state", () => ({
        showDialog: false,
        errorMessage: "",
        redirectPath: "",
    }));

    const router = useRouter();
    const { signOutUser } = useFirebaseAuth();

    // Intercept 401/403 errors globally by overriding $fetch
    const setupGlobalErrorInterceptor = () => {
        globalThis.$fetch = ofetch.create({
            onResponseError({ response }) {
                // Check for auth errors (401/403)
                if (response.status === 401 || response.status === 403) {
                    const responseData = response._data || {};
                    const errorMsg =
                        responseData.message ||
                        responseData.error ||
                        "Authentication error";

                    // Likely token expiration if error contains these terms
                    const isTokenError =
                        errorMsg.includes("token") ||
                        errorMsg.includes("expired") ||
                        errorMsg.includes("authentication failed");

                    if (isTokenError) {
                        handleAuthError(
                            "Your session has expired. Please sign in again.",
                            router.currentRoute.value.fullPath
                        );

                        // Prevent further error handling
                        return new Response(null, { status: 401 });
                    }
                }
            },
        });
    };

    // Handle auth errors
    const handleAuthError = (message: string, returnPath?: string) => {
        authErrorState.value = {
            showDialog: true,
            errorMessage: message,
            redirectPath: returnPath || "/",
        };
    };

    // Dismiss dialog and redirect to login
    const redirectToLogin = async () => {
        try {
            // Hide dialog
            authErrorState.value.showDialog = false;

            // Sign out user (clear state)
            await signOutUser();

            // Navigate to login with redirect
            const redirectPath = authErrorState.value.redirectPath;
            await navigateTo(
                `/auth/login?redirect=${encodeURIComponent(redirectPath)}`
            );
        } catch (error) {
            console.error("Failed to redirect to login:", error);
            // Only use window.location as a fallback if navigateTo fails
            window.location.href = "/auth/login";
        }
    };

    return {
        setupGlobalErrorInterceptor,
        handleAuthError,
        redirectToLogin,
        authErrorState,
    };
}
