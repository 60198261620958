export default {
  admin: () => import("/workspace/layouts/admin.vue").then(m => m.default || m),
  "auth-scribe": () => import("/workspace/layouts/auth-scribe.vue").then(m => m.default || m),
  auth: () => import("/workspace/layouts/auth.vue").then(m => m.default || m),
  dashboard: () => import("/workspace/layouts/dashboard.vue").then(m => m.default || m),
  default: () => import("/workspace/layouts/default.vue").then(m => m.default || m),
  home: () => import("/workspace/layouts/home.vue").then(m => m.default || m),
  landing: () => import("/workspace/layouts/landing.vue").then(m => m.default || m),
  onboarding: () => import("/workspace/layouts/onboarding.vue").then(m => m.default || m),
  print: () => import("/workspace/layouts/print.vue").then(m => m.default || m),
  scribe: () => import("/workspace/layouts/scribe.vue").then(m => m.default || m)
}