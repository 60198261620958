import { initializeApp } from "firebase/app";
import { type Auth } from "firebase/auth";
import {
    initializeAuth,
    browserLocalPersistence,
    browserSessionPersistence,
    indexedDBLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export default defineNuxtPlugin(async (nuxtApp) => {
    const config = useRuntimeConfig();

    const firebaseConfig = {
        apiKey: config.public.FIREBASE_API_KEY,
        authDomain: "nomadrx-prod.firebaseapp.com",
        projectId: "nomadrx-prod",
        storageBucket: "nomadrx-prod.appspot.com",
        messagingSenderId: "587104936999",
        appId: "1:587104936999:web:329fd847615e89fee15a02",
        measurementId: "G-T61CC2TGRG",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Initialize Auth with persistence
    const auth = initializeAuth(app, {
        persistence: [
            indexedDBLocalPersistence,
            browserLocalPersistence,
            browserSessionPersistence,
        ],
        popupRedirectResolver: null,
    });

    const firestore = getFirestore(app);

    // Initialize auth state
    await auth.authStateReady();

    return {
        provide: {
            firebaseAuth: auth,
            firestoreDb: firestore,
        },
    };
});
