<!-- components/AuthErrorDialog.vue -->
<template>
    <UDashboardModal v-model="showDialog" prevent-close :ui="{
        overlay: {
            background: 'bg-gray-50 dark:bg-gray-800',
        },
    }">
        <!-- <UCard> -->
        <template #title>
            <div class="flex items-center gap-2">
                <UIcon name="i-heroicons-shield-exclamation-solid" class="text-gray-300 w-5 h-5" />
                <h3 class="text-base font-semibold leading-6">
                    Authentication
                </h3>
            </div>
        </template>
        <template #description>
            <p>{{ errorMessage }}</p>
        </template>



        <template #footer>
            <div class="flex justify-end gap-3">
                <UButton label="Sign back in" color="black" variant="solid" @click="redirectToLogin"
                    icon="i-heroicons-arrow-right" trailing />
            </div>
        </template>
        <!-- </UCard> -->
    </UDashboardModal>
</template>

<script setup>
const { authErrorState, redirectToLogin } = useAuthErrorHandler()

const showDialog = computed({
    get: () => authErrorState.value.showDialog,
    set: (value) => authErrorState.value.showDialog = value
})

const errorMessage = computed(() =>
    authErrorState.value.errorMessage || 'Your session has expired. Please sign in again.'
)
</script>