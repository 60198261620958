import { default as addressestDsPVXSYPmMeta } from "/workspace/pages/admin/accounts/[id]/addresses.vue?macro=true";
import { default as billingeKaDTUMgwCMeta } from "/workspace/pages/admin/accounts/[id]/billing.vue?macro=true";
import { default as clients6hrHHYqBTVMeta } from "/workspace/pages/admin/accounts/[id]/clients.vue?macro=true";
import { default as index589QgCX3eHMeta } from "/workspace/pages/admin/accounts/[id]/index.vue?macro=true";
import { default as settingsP3Z6ZSe1B5Meta } from "/workspace/pages/admin/accounts/[id]/settings.vue?macro=true";
import { default as users5sKV7fUsiCMeta } from "/workspace/pages/admin/accounts/[id]/users.vue?macro=true";
import { default as _91id_9359qPUIQuijMeta } from "/workspace/pages/admin/accounts/[id].vue?macro=true";
import { default as index23DwFceEAPMeta } from "/workspace/pages/admin/accounts/index.vue?macro=true";
import { default as accountsi4ZJ4jmxlaMeta } from "/workspace/pages/admin/accounts.vue?macro=true";
import { default as index9Aa4nxzuPxMeta } from "/workspace/pages/admin/addresses/index.vue?macro=true";
import { default as indexsUM22KzZ1KMeta } from "/workspace/pages/admin/auth/[id]/index.vue?macro=true";
import { default as indexYKG2l9u3gUMeta } from "/workspace/pages/admin/auth/index.vue?macro=true";
import { default as indexSdgMsV94xqMeta } from "/workspace/pages/admin/claims/[id]/index.vue?macro=true";
import { default as invoiceK7SV7EOGpmMeta } from "/workspace/pages/admin/claims/[id]/invoice.vue?macro=true";
import { default as paymentsaYtRdtI2XHMeta } from "/workspace/pages/admin/claims/[id]/payments.vue?macro=true";
import { default as printZBD5WJftnZMeta } from "/workspace/pages/admin/claims/[id]/print.vue?macro=true";
import { default as _91id_93Swf0yPZzLEMeta } from "/workspace/pages/admin/claims/[id].vue?macro=true";
import { default as indexRo6gOS5ib9Meta } from "/workspace/pages/admin/claims/index.vue?macro=true";
import { default as claimsD60w0ykpW0Meta } from "/workspace/pages/admin/claims.vue?macro=true";
import { default as index4Vtfw47pWwMeta } from "/workspace/pages/admin/clients/[id]/index.vue?macro=true";
import { default as indexFl5XsfIUmTMeta } from "/workspace/pages/admin/clients/index.vue?macro=true";
import { default as clientsYpRhsnhbptMeta } from "/workspace/pages/admin/clients.vue?macro=true";
import { default as _91id_93FlqXFY4nT6Meta } from "/workspace/pages/admin/extractions/[id].vue?macro=true";
import { default as indexl5CcBdqTgrMeta } from "/workspace/pages/admin/extractions/index.vue?macro=true";
import { default as extractionsHWmgxdHL8GMeta } from "/workspace/pages/admin/extractions.vue?macro=true";
import { default as index8x3i9klHioMeta } from "/workspace/pages/admin/index.vue?macro=true";
import { default as indexEtFGXN7dQNMeta } from "/workspace/pages/admin/insurance-payment-items/index.vue?macro=true";
import { default as indexpeUap82ahkMeta } from "/workspace/pages/admin/insurance-payments/[id]/index.vue?macro=true";
import { default as index9GVYd38lz0Meta } from "/workspace/pages/admin/insurance-payments/index.vue?macro=true";
import { default as insurance_45paymentsnMH3oK7Is8Meta } from "/workspace/pages/admin/insurance-payments.vue?macro=true";
import { default as indexWAZRaRwjuoMeta } from "/workspace/pages/admin/insurance/[id]/index.vue?macro=true";
import { default as indexzNkEkS5u1zMeta } from "/workspace/pages/admin/insurance/index.vue?macro=true";
import { default as indexondkKIRLhQMeta } from "/workspace/pages/admin/numo-companies/[id]/index.vue?macro=true";
import { default as indexFB2ABuwaM0Meta } from "/workspace/pages/admin/numo-companies/index.vue?macro=true";
import { default as numo_45companiesMu6SZqXM71Meta } from "/workspace/pages/admin/numo-companies.vue?macro=true";
import { default as index4ZWpTkF6ReMeta } from "/workspace/pages/admin/numo-invoices/index.vue?macro=true";
import { default as indexn3uS1UBYdWMeta } from "/workspace/pages/admin/partners/[id]/index.vue?macro=true";
import { default as payoutsxSFpcHKwRzMeta } from "/workspace/pages/admin/partners/[id]/payouts.vue?macro=true";
import { default as products19oMpcv7NKMeta } from "/workspace/pages/admin/partners/[id]/products.vue?macro=true";
import { default as providersnZy6MnK7ZyMeta } from "/workspace/pages/admin/partners/[id]/providers.vue?macro=true";
import { default as _91id_93dvcZJQ3orBMeta } from "/workspace/pages/admin/partners/[id].vue?macro=true";
import { default as indexEhbXh5e9e8Meta } from "/workspace/pages/admin/partners/index.vue?macro=true";
import { default as partnersLxCbavIyaEMeta } from "/workspace/pages/admin/partners.vue?macro=true";
import { default as indexFnvD3mAT33Meta } from "/workspace/pages/admin/payment-items/index.vue?macro=true";
import { default as indexNht4FtchG1Meta } from "/workspace/pages/admin/payments-client/[id]/index.vue?macro=true";
import { default as index4S7jGQJaXxMeta } from "/workspace/pages/admin/payments-client/index.vue?macro=true";
import { default as indexdJc8yEzcagMeta } from "/workspace/pages/admin/payments/[id]/index.vue?macro=true";
import { default as indexWqx06a0LMeMeta } from "/workspace/pages/admin/payments/index.vue?macro=true";
import { default as paymentsj6fw8KBVHZMeta } from "/workspace/pages/admin/payments.vue?macro=true";
import { default as indexvXvV1kZAFQMeta } from "/workspace/pages/admin/payout-items/index.vue?macro=true";
import { default as indexFrscT2sWUxMeta } from "/workspace/pages/admin/payouts/[id]/index.vue?macro=true";
import { default as indexUchLdHrAqOMeta } from "/workspace/pages/admin/payouts/index.vue?macro=true";
import { default as payoutsrEgGAQS618Meta } from "/workspace/pages/admin/payouts.vue?macro=true";
import { default as indexfhR0bt52bzMeta } from "/workspace/pages/admin/policy/index.vue?macro=true";
import { default as index59MIkUB5IWMeta } from "/workspace/pages/admin/providers/index.vue?macro=true";
import { default as indexK4pDmDSPxYMeta } from "/workspace/pages/admin/users-fb/[id]/index.vue?macro=true";
import { default as index786oWL2DabMeta } from "/workspace/pages/admin/users-fb/index.vue?macro=true";
import { default as indexO9tuDy4tJxMeta } from "/workspace/pages/admin/users/[id]/index.vue?macro=true";
import { default as index7Q2JOnQTqrMeta } from "/workspace/pages/admin/users/index.vue?macro=true";
import { default as admintMKYXMmtvkMeta } from "/workspace/pages/admin.vue?macro=true";
import { default as action89Kg0P55P6Meta } from "/workspace/pages/auth/action.vue?macro=true";
import { default as loginipm5N7UCSPMeta } from "/workspace/pages/auth/login.vue?macro=true";
import { default as confirmG7YZz1w9hpMeta } from "/workspace/pages/auth/reset/confirm.vue?macro=true";
import { default as indext6arxR9eOoMeta } from "/workspace/pages/auth/reset/index.vue?macro=true";
import { default as signupzo4AUvLtwkMeta } from "/workspace/pages/auth/signup.vue?macro=true";
import { default as indexmlSXcZRmpxMeta } from "/workspace/pages/blog/[categorySlug]/[postSlug]/index.vue?macro=true";
import { default as indexRgrKfU8NaHMeta } from "/workspace/pages/blog/index.vue?macro=true";
import { default as admissionorRKI4t9EYMeta } from "/workspace/pages/dashboard/authorizations/[id]/admission.vue?macro=true";
import { default as costLOZ4KE25KOMeta } from "/workspace/pages/dashboard/authorizations/[id]/cost.vue?macro=true";
import { default as diagnosisfN1dpRZUkTMeta } from "/workspace/pages/dashboard/authorizations/[id]/diagnosis.vue?macro=true";
import { default as documentsCrKZ3II44HMeta } from "/workspace/pages/dashboard/authorizations/[id]/documents.vue?macro=true";
import { default as indexLkOYDqDSNJMeta } from "/workspace/pages/dashboard/authorizations/[id]/index.vue?macro=true";
import { default as teamzLQrSFuPvqMeta } from "/workspace/pages/dashboard/authorizations/[id]/team.vue?macro=true";
import { default as treatmentuGDUxup8pUMeta } from "/workspace/pages/dashboard/authorizations/[id]/treatment.vue?macro=true";
import { default as _91id_93T1HRjNqMITMeta } from "/workspace/pages/dashboard/authorizations/[id].vue?macro=true";
import { default as indexUekENRwKmWMeta } from "/workspace/pages/dashboard/authorizations/index.vue?macro=true";
import { default as authorizations1I10MCutkhMeta } from "/workspace/pages/dashboard/authorizations.vue?macro=true";
import { default as _91id_93WWB9ZP9zmLMeta } from "/workspace/pages/dashboard/claims/[id].vue?macro=true";
import { default as claims8UzpXkD7zNMeta } from "/workspace/pages/dashboard/claims.vue?macro=true";
import { default as _91id_93xnRmmsZxEnMeta } from "/workspace/pages/dashboard/clients/[id].vue?macro=true";
import { default as indexROEunKH4ApMeta } from "/workspace/pages/dashboard/clients/index.vue?macro=true";
import { default as clientsEseddXbLLRMeta } from "/workspace/pages/dashboard/clients.vue?macro=true";
import { default as index2lrBtCOh90Meta } from "/workspace/pages/dashboard/index.vue?macro=true";
import { default as _91id_9304LiK5YMTVMeta } from "/workspace/pages/dashboard/payouts/[id].vue?macro=true";
import { default as indexpNhBYRpF8XMeta } from "/workspace/pages/dashboard/payouts/index.vue?macro=true";
import { default as payouts3nVadYxOwoMeta } from "/workspace/pages/dashboard/payouts.vue?macro=true";
import { default as indexnPOa0M2XREMeta } from "/workspace/pages/dashboard/settings/index.vue?macro=true";
import { default as settingsgJ3MGvfJFXMeta } from "/workspace/pages/dashboard/settings.vue?macro=true";
import { default as dashboardKIhr9Z9Xg1Meta } from "/workspace/pages/dashboard.vue?macro=true";
import { default as _91city_id_932jux7r4bRsMeta } from "/workspace/pages/find-care/[city_id].vue?macro=true";
import { default as indexLI6LpVIusSMeta } from "/workspace/pages/for-patients/index.vue?macro=true";
import { default as indexxtUpaPOwALMeta } from "/workspace/pages/for-providers/index.vue?macro=true";
import { default as indexw2hMVXqgujMeta } from "/workspace/pages/home/billing/index.vue?macro=true";
import { default as indexdE734hEblTMeta } from "/workspace/pages/home/claims/index.vue?macro=true";
import { default as indexMeYG1zOMoTMeta } from "/workspace/pages/home/index.vue?macro=true";
import { default as index9dcnwbPhttMeta } from "/workspace/pages/home/insurance/index.vue?macro=true";
import { default as indexROv3KMKUbeMeta } from "/workspace/pages/home/providers/index.vue?macro=true";
import { default as indexaV1Sgoym5rMeta } from "/workspace/pages/home/settings/index.vue?macro=true";
import { default as homehESkjZ67H4Meta } from "/workspace/pages/home.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as loadingYin3E062Q1Meta } from "/workspace/pages/loading.vue?macro=true";
import { default as indexdEfuMmlaquMeta } from "/workspace/pages/login-verify/index.vue?macro=true";
import { default as indextTkPG1jUYQMeta } from "/workspace/pages/login/index.vue?macro=true";
import { default as _91partner_slug_93wEKnrLzytPMeta } from "/workspace/pages/onboard/[partner_slug].vue?macro=true";
import { default as index9bLc195l0FMeta } from "/workspace/pages/onboard/address/index.vue?macro=true";
import { default as indexqaRTieiQTAMeta } from "/workspace/pages/onboard/billing/index.vue?macro=true";
import { default as indexep2FVeuO1hMeta } from "/workspace/pages/onboard/card-upload/index.vue?macro=true";
import { default as index7pvcQ4WbaeMeta } from "/workspace/pages/onboard/clients/index.vue?macro=true";
import { default as indexekDEk6fbD7Meta } from "/workspace/pages/onboard/eligibility/index.vue?macro=true";
import { default as indexzAVvhj0a33Meta } from "/workspace/pages/onboard/index.vue?macro=true";
import { default as indexk4RQNAn1A2Meta } from "/workspace/pages/onboard/insurance/index.vue?macro=true";
import { default as onboard8CtHldbEa1Meta } from "/workspace/pages/onboard.vue?macro=true";
import { default as index0dcsGmFZa9Meta } from "/workspace/pages/print/invoice/[id]/index.vue?macro=true";
import { default as bank_45info0vJ72NeQEuMeta } from "/workspace/pages/print/partner/[partner_id]/bank-info.vue?macro=true";
import { default as index21M5iF70M6Meta } from "/workspace/pages/privacy-policy/index.vue?macro=true";
import { default as indexcPdLjFv6ymMeta } from "/workspace/pages/providers/[slug]/index.vue?macro=true";
import { default as _91id_93kUyEJHJhkyMeta } from "/workspace/pages/scribe/[id].vue?macro=true";
import { default as _91doc_id_93HHCjgg8ZKiMeta } from "/workspace/pages/scribe/doc/[doc_id].vue?macro=true";
import { default as indexItfVSR6KgoMeta } from "/workspace/pages/scribe/index.vue?macro=true";
import { default as indexAzVYbIFZwfMeta } from "/workspace/pages/scribe/settings/index.vue?macro=true";
import { default as scribeLnBEMA1wUiMeta } from "/workspace/pages/scribe.vue?macro=true";
import { default as index9xmF4TLFhBMeta } from "/workspace/pages/terms-of-use/index.vue?macro=true";
export default [
  {
    name: admintMKYXMmtvkMeta?.name ?? undefined,
    path: admintMKYXMmtvkMeta?.path ?? "/admin",
    meta: admintMKYXMmtvkMeta || {},
    alias: admintMKYXMmtvkMeta?.alias || [],
    redirect: admintMKYXMmtvkMeta?.redirect,
    component: () => import("/workspace/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: accountsi4ZJ4jmxlaMeta?.name ?? undefined,
    path: accountsi4ZJ4jmxlaMeta?.path ?? "accounts",
    meta: accountsi4ZJ4jmxlaMeta || {},
    alias: accountsi4ZJ4jmxlaMeta?.alias || [],
    redirect: accountsi4ZJ4jmxlaMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_9359qPUIQuijMeta?.name ?? undefined,
    path: _91id_9359qPUIQuijMeta?.path ?? ":id()",
    meta: _91id_9359qPUIQuijMeta || {},
    alias: _91id_9359qPUIQuijMeta?.alias || [],
    redirect: _91id_9359qPUIQuijMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/[id].vue").then(m => m.default || m),
    children: [
  {
    name: addressestDsPVXSYPmMeta?.name ?? "admin-accounts-id-addresses",
    path: addressestDsPVXSYPmMeta?.path ?? "addresses",
    meta: addressestDsPVXSYPmMeta || {},
    alias: addressestDsPVXSYPmMeta?.alias || [],
    redirect: addressestDsPVXSYPmMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/[id]/addresses.vue").then(m => m.default || m)
  },
  {
    name: billingeKaDTUMgwCMeta?.name ?? "admin-accounts-id-billing",
    path: billingeKaDTUMgwCMeta?.path ?? "billing",
    meta: billingeKaDTUMgwCMeta || {},
    alias: billingeKaDTUMgwCMeta?.alias || [],
    redirect: billingeKaDTUMgwCMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/[id]/billing.vue").then(m => m.default || m)
  },
  {
    name: clients6hrHHYqBTVMeta?.name ?? "admin-accounts-id-clients",
    path: clients6hrHHYqBTVMeta?.path ?? "clients",
    meta: clients6hrHHYqBTVMeta || {},
    alias: clients6hrHHYqBTVMeta?.alias || [],
    redirect: clients6hrHHYqBTVMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/[id]/clients.vue").then(m => m.default || m)
  },
  {
    name: index589QgCX3eHMeta?.name ?? "admin-accounts-id",
    path: index589QgCX3eHMeta?.path ?? "",
    meta: index589QgCX3eHMeta || {},
    alias: index589QgCX3eHMeta?.alias || [],
    redirect: index589QgCX3eHMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: settingsP3Z6ZSe1B5Meta?.name ?? "admin-accounts-id-settings",
    path: settingsP3Z6ZSe1B5Meta?.path ?? "settings",
    meta: settingsP3Z6ZSe1B5Meta || {},
    alias: settingsP3Z6ZSe1B5Meta?.alias || [],
    redirect: settingsP3Z6ZSe1B5Meta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: users5sKV7fUsiCMeta?.name ?? "admin-accounts-id-users",
    path: users5sKV7fUsiCMeta?.path ?? "users",
    meta: users5sKV7fUsiCMeta || {},
    alias: users5sKV7fUsiCMeta?.alias || [],
    redirect: users5sKV7fUsiCMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/[id]/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index23DwFceEAPMeta?.name ?? "admin-accounts",
    path: index23DwFceEAPMeta?.path ?? "",
    meta: index23DwFceEAPMeta || {},
    alias: index23DwFceEAPMeta?.alias || [],
    redirect: index23DwFceEAPMeta?.redirect,
    component: () => import("/workspace/pages/admin/accounts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index9Aa4nxzuPxMeta?.name ?? "admin-addresses",
    path: index9Aa4nxzuPxMeta?.path ?? "addresses",
    meta: index9Aa4nxzuPxMeta || {},
    alias: index9Aa4nxzuPxMeta?.alias || [],
    redirect: index9Aa4nxzuPxMeta?.redirect,
    component: () => import("/workspace/pages/admin/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: indexsUM22KzZ1KMeta?.name ?? "admin-auth-id",
    path: indexsUM22KzZ1KMeta?.path ?? "auth/:id()",
    meta: indexsUM22KzZ1KMeta || {},
    alias: indexsUM22KzZ1KMeta?.alias || [],
    redirect: indexsUM22KzZ1KMeta?.redirect,
    component: () => import("/workspace/pages/admin/auth/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYKG2l9u3gUMeta?.name ?? "admin-auth",
    path: indexYKG2l9u3gUMeta?.path ?? "auth",
    meta: indexYKG2l9u3gUMeta || {},
    alias: indexYKG2l9u3gUMeta?.alias || [],
    redirect: indexYKG2l9u3gUMeta?.redirect,
    component: () => import("/workspace/pages/admin/auth/index.vue").then(m => m.default || m)
  },
  {
    name: claimsD60w0ykpW0Meta?.name ?? undefined,
    path: claimsD60w0ykpW0Meta?.path ?? "claims",
    meta: claimsD60w0ykpW0Meta || {},
    alias: claimsD60w0ykpW0Meta?.alias || [],
    redirect: claimsD60w0ykpW0Meta?.redirect,
    component: () => import("/workspace/pages/admin/claims.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93Swf0yPZzLEMeta?.name ?? undefined,
    path: _91id_93Swf0yPZzLEMeta?.path ?? ":id()",
    meta: _91id_93Swf0yPZzLEMeta || {},
    alias: _91id_93Swf0yPZzLEMeta?.alias || [],
    redirect: _91id_93Swf0yPZzLEMeta?.redirect,
    component: () => import("/workspace/pages/admin/claims/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexSdgMsV94xqMeta?.name ?? "admin-claims-id",
    path: indexSdgMsV94xqMeta?.path ?? "",
    meta: indexSdgMsV94xqMeta || {},
    alias: indexSdgMsV94xqMeta?.alias || [],
    redirect: indexSdgMsV94xqMeta?.redirect,
    component: () => import("/workspace/pages/admin/claims/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: invoiceK7SV7EOGpmMeta?.name ?? "admin-claims-id-invoice",
    path: invoiceK7SV7EOGpmMeta?.path ?? "invoice",
    meta: invoiceK7SV7EOGpmMeta || {},
    alias: invoiceK7SV7EOGpmMeta?.alias || [],
    redirect: invoiceK7SV7EOGpmMeta?.redirect,
    component: () => import("/workspace/pages/admin/claims/[id]/invoice.vue").then(m => m.default || m)
  },
  {
    name: paymentsaYtRdtI2XHMeta?.name ?? "admin-claims-id-payments",
    path: paymentsaYtRdtI2XHMeta?.path ?? "payments",
    meta: paymentsaYtRdtI2XHMeta || {},
    alias: paymentsaYtRdtI2XHMeta?.alias || [],
    redirect: paymentsaYtRdtI2XHMeta?.redirect,
    component: () => import("/workspace/pages/admin/claims/[id]/payments.vue").then(m => m.default || m)
  },
  {
    name: printZBD5WJftnZMeta?.name ?? "admin-claims-id-print",
    path: printZBD5WJftnZMeta?.path ?? "print",
    meta: printZBD5WJftnZMeta || {},
    alias: printZBD5WJftnZMeta?.alias || [],
    redirect: printZBD5WJftnZMeta?.redirect,
    component: () => import("/workspace/pages/admin/claims/[id]/print.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexRo6gOS5ib9Meta?.name ?? "admin-claims",
    path: indexRo6gOS5ib9Meta?.path ?? "",
    meta: indexRo6gOS5ib9Meta || {},
    alias: indexRo6gOS5ib9Meta?.alias || [],
    redirect: indexRo6gOS5ib9Meta?.redirect,
    component: () => import("/workspace/pages/admin/claims/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: clientsYpRhsnhbptMeta?.name ?? undefined,
    path: clientsYpRhsnhbptMeta?.path ?? "clients",
    meta: clientsYpRhsnhbptMeta || {},
    alias: clientsYpRhsnhbptMeta?.alias || [],
    redirect: clientsYpRhsnhbptMeta?.redirect,
    component: () => import("/workspace/pages/admin/clients.vue").then(m => m.default || m),
    children: [
  {
    name: index4Vtfw47pWwMeta?.name ?? "admin-clients-id",
    path: index4Vtfw47pWwMeta?.path ?? ":id()",
    meta: index4Vtfw47pWwMeta || {},
    alias: index4Vtfw47pWwMeta?.alias || [],
    redirect: index4Vtfw47pWwMeta?.redirect,
    component: () => import("/workspace/pages/admin/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFl5XsfIUmTMeta?.name ?? "admin-clients",
    path: indexFl5XsfIUmTMeta?.path ?? "",
    meta: indexFl5XsfIUmTMeta || {},
    alias: indexFl5XsfIUmTMeta?.alias || [],
    redirect: indexFl5XsfIUmTMeta?.redirect,
    component: () => import("/workspace/pages/admin/clients/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: extractionsHWmgxdHL8GMeta?.name ?? undefined,
    path: extractionsHWmgxdHL8GMeta?.path ?? "extractions",
    meta: extractionsHWmgxdHL8GMeta || {},
    alias: extractionsHWmgxdHL8GMeta?.alias || [],
    redirect: extractionsHWmgxdHL8GMeta?.redirect,
    component: () => import("/workspace/pages/admin/extractions.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93FlqXFY4nT6Meta?.name ?? "admin-extractions-id",
    path: _91id_93FlqXFY4nT6Meta?.path ?? ":id()",
    meta: _91id_93FlqXFY4nT6Meta || {},
    alias: _91id_93FlqXFY4nT6Meta?.alias || [],
    redirect: _91id_93FlqXFY4nT6Meta?.redirect,
    component: () => import("/workspace/pages/admin/extractions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexl5CcBdqTgrMeta?.name ?? "admin-extractions",
    path: indexl5CcBdqTgrMeta?.path ?? "",
    meta: indexl5CcBdqTgrMeta || {},
    alias: indexl5CcBdqTgrMeta?.alias || [],
    redirect: indexl5CcBdqTgrMeta?.redirect,
    component: () => import("/workspace/pages/admin/extractions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8x3i9klHioMeta?.name ?? "admin",
    path: index8x3i9klHioMeta?.path ?? "",
    meta: index8x3i9klHioMeta || {},
    alias: index8x3i9klHioMeta?.alias || [],
    redirect: index8x3i9klHioMeta?.redirect,
    component: () => import("/workspace/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexEtFGXN7dQNMeta?.name ?? "admin-insurance-payment-items",
    path: indexEtFGXN7dQNMeta?.path ?? "insurance-payment-items",
    meta: indexEtFGXN7dQNMeta || {},
    alias: indexEtFGXN7dQNMeta?.alias || [],
    redirect: indexEtFGXN7dQNMeta?.redirect,
    component: () => import("/workspace/pages/admin/insurance-payment-items/index.vue").then(m => m.default || m)
  },
  {
    name: insurance_45paymentsnMH3oK7Is8Meta?.name ?? undefined,
    path: insurance_45paymentsnMH3oK7Is8Meta?.path ?? "insurance-payments",
    meta: insurance_45paymentsnMH3oK7Is8Meta || {},
    alias: insurance_45paymentsnMH3oK7Is8Meta?.alias || [],
    redirect: insurance_45paymentsnMH3oK7Is8Meta?.redirect,
    component: () => import("/workspace/pages/admin/insurance-payments.vue").then(m => m.default || m),
    children: [
  {
    name: indexpeUap82ahkMeta?.name ?? "admin-insurance-payments-id",
    path: indexpeUap82ahkMeta?.path ?? ":id()",
    meta: indexpeUap82ahkMeta || {},
    alias: indexpeUap82ahkMeta?.alias || [],
    redirect: indexpeUap82ahkMeta?.redirect,
    component: () => import("/workspace/pages/admin/insurance-payments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index9GVYd38lz0Meta?.name ?? "admin-insurance-payments",
    path: index9GVYd38lz0Meta?.path ?? "",
    meta: index9GVYd38lz0Meta || {},
    alias: index9GVYd38lz0Meta?.alias || [],
    redirect: index9GVYd38lz0Meta?.redirect,
    component: () => import("/workspace/pages/admin/insurance-payments/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexWAZRaRwjuoMeta?.name ?? "admin-insurance-id",
    path: indexWAZRaRwjuoMeta?.path ?? "insurance/:id()",
    meta: indexWAZRaRwjuoMeta || {},
    alias: indexWAZRaRwjuoMeta?.alias || [],
    redirect: indexWAZRaRwjuoMeta?.redirect,
    component: () => import("/workspace/pages/admin/insurance/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNkEkS5u1zMeta?.name ?? "admin-insurance",
    path: indexzNkEkS5u1zMeta?.path ?? "insurance",
    meta: indexzNkEkS5u1zMeta || {},
    alias: indexzNkEkS5u1zMeta?.alias || [],
    redirect: indexzNkEkS5u1zMeta?.redirect,
    component: () => import("/workspace/pages/admin/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: numo_45companiesMu6SZqXM71Meta?.name ?? undefined,
    path: numo_45companiesMu6SZqXM71Meta?.path ?? "numo-companies",
    meta: numo_45companiesMu6SZqXM71Meta || {},
    alias: numo_45companiesMu6SZqXM71Meta?.alias || [],
    redirect: numo_45companiesMu6SZqXM71Meta?.redirect,
    component: () => import("/workspace/pages/admin/numo-companies.vue").then(m => m.default || m),
    children: [
  {
    name: indexondkKIRLhQMeta?.name ?? "admin-numo-companies-id",
    path: indexondkKIRLhQMeta?.path ?? ":id()",
    meta: indexondkKIRLhQMeta || {},
    alias: indexondkKIRLhQMeta?.alias || [],
    redirect: indexondkKIRLhQMeta?.redirect,
    component: () => import("/workspace/pages/admin/numo-companies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFB2ABuwaM0Meta?.name ?? "admin-numo-companies",
    path: indexFB2ABuwaM0Meta?.path ?? "",
    meta: indexFB2ABuwaM0Meta || {},
    alias: indexFB2ABuwaM0Meta?.alias || [],
    redirect: indexFB2ABuwaM0Meta?.redirect,
    component: () => import("/workspace/pages/admin/numo-companies/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index4ZWpTkF6ReMeta?.name ?? "admin-numo-invoices",
    path: index4ZWpTkF6ReMeta?.path ?? "numo-invoices",
    meta: index4ZWpTkF6ReMeta || {},
    alias: index4ZWpTkF6ReMeta?.alias || [],
    redirect: index4ZWpTkF6ReMeta?.redirect,
    component: () => import("/workspace/pages/admin/numo-invoices/index.vue").then(m => m.default || m)
  },
  {
    name: partnersLxCbavIyaEMeta?.name ?? undefined,
    path: partnersLxCbavIyaEMeta?.path ?? "partners",
    meta: partnersLxCbavIyaEMeta || {},
    alias: partnersLxCbavIyaEMeta?.alias || [],
    redirect: partnersLxCbavIyaEMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93dvcZJQ3orBMeta?.name ?? undefined,
    path: _91id_93dvcZJQ3orBMeta?.path ?? ":id()",
    meta: _91id_93dvcZJQ3orBMeta || {},
    alias: _91id_93dvcZJQ3orBMeta?.alias || [],
    redirect: _91id_93dvcZJQ3orBMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexn3uS1UBYdWMeta?.name ?? "admin-partners-id",
    path: indexn3uS1UBYdWMeta?.path ?? "",
    meta: indexn3uS1UBYdWMeta || {},
    alias: indexn3uS1UBYdWMeta?.alias || [],
    redirect: indexn3uS1UBYdWMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: payoutsxSFpcHKwRzMeta?.name ?? "admin-partners-id-payouts",
    path: payoutsxSFpcHKwRzMeta?.path ?? "payouts",
    meta: payoutsxSFpcHKwRzMeta || {},
    alias: payoutsxSFpcHKwRzMeta?.alias || [],
    redirect: payoutsxSFpcHKwRzMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners/[id]/payouts.vue").then(m => m.default || m)
  },
  {
    name: products19oMpcv7NKMeta?.name ?? "admin-partners-id-products",
    path: products19oMpcv7NKMeta?.path ?? "products",
    meta: products19oMpcv7NKMeta || {},
    alias: products19oMpcv7NKMeta?.alias || [],
    redirect: products19oMpcv7NKMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners/[id]/products.vue").then(m => m.default || m)
  },
  {
    name: providersnZy6MnK7ZyMeta?.name ?? "admin-partners-id-providers",
    path: providersnZy6MnK7ZyMeta?.path ?? "providers",
    meta: providersnZy6MnK7ZyMeta || {},
    alias: providersnZy6MnK7ZyMeta?.alias || [],
    redirect: providersnZy6MnK7ZyMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners/[id]/providers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexEhbXh5e9e8Meta?.name ?? "admin-partners",
    path: indexEhbXh5e9e8Meta?.path ?? "",
    meta: indexEhbXh5e9e8Meta || {},
    alias: indexEhbXh5e9e8Meta?.alias || [],
    redirect: indexEhbXh5e9e8Meta?.redirect,
    component: () => import("/workspace/pages/admin/partners/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFnvD3mAT33Meta?.name ?? "admin-payment-items",
    path: indexFnvD3mAT33Meta?.path ?? "payment-items",
    meta: indexFnvD3mAT33Meta || {},
    alias: indexFnvD3mAT33Meta?.alias || [],
    redirect: indexFnvD3mAT33Meta?.redirect,
    component: () => import("/workspace/pages/admin/payment-items/index.vue").then(m => m.default || m)
  },
  {
    name: indexNht4FtchG1Meta?.name ?? "admin-payments-client-id",
    path: indexNht4FtchG1Meta?.path ?? "payments-client/:id()",
    meta: indexNht4FtchG1Meta || {},
    alias: indexNht4FtchG1Meta?.alias || [],
    redirect: indexNht4FtchG1Meta?.redirect,
    component: () => import("/workspace/pages/admin/payments-client/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index4S7jGQJaXxMeta?.name ?? "admin-payments-client",
    path: index4S7jGQJaXxMeta?.path ?? "payments-client",
    meta: index4S7jGQJaXxMeta || {},
    alias: index4S7jGQJaXxMeta?.alias || [],
    redirect: index4S7jGQJaXxMeta?.redirect,
    component: () => import("/workspace/pages/admin/payments-client/index.vue").then(m => m.default || m)
  },
  {
    name: paymentsj6fw8KBVHZMeta?.name ?? undefined,
    path: paymentsj6fw8KBVHZMeta?.path ?? "payments",
    meta: paymentsj6fw8KBVHZMeta || {},
    alias: paymentsj6fw8KBVHZMeta?.alias || [],
    redirect: paymentsj6fw8KBVHZMeta?.redirect,
    component: () => import("/workspace/pages/admin/payments.vue").then(m => m.default || m),
    children: [
  {
    name: indexdJc8yEzcagMeta?.name ?? "admin-payments-id",
    path: indexdJc8yEzcagMeta?.path ?? ":id()",
    meta: indexdJc8yEzcagMeta || {},
    alias: indexdJc8yEzcagMeta?.alias || [],
    redirect: indexdJc8yEzcagMeta?.redirect,
    component: () => import("/workspace/pages/admin/payments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWqx06a0LMeMeta?.name ?? "admin-payments",
    path: indexWqx06a0LMeMeta?.path ?? "",
    meta: indexWqx06a0LMeMeta || {},
    alias: indexWqx06a0LMeMeta?.alias || [],
    redirect: indexWqx06a0LMeMeta?.redirect,
    component: () => import("/workspace/pages/admin/payments/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexvXvV1kZAFQMeta?.name ?? "admin-payout-items",
    path: indexvXvV1kZAFQMeta?.path ?? "payout-items",
    meta: indexvXvV1kZAFQMeta || {},
    alias: indexvXvV1kZAFQMeta?.alias || [],
    redirect: indexvXvV1kZAFQMeta?.redirect,
    component: () => import("/workspace/pages/admin/payout-items/index.vue").then(m => m.default || m)
  },
  {
    name: payoutsrEgGAQS618Meta?.name ?? undefined,
    path: payoutsrEgGAQS618Meta?.path ?? "payouts",
    meta: payoutsrEgGAQS618Meta || {},
    alias: payoutsrEgGAQS618Meta?.alias || [],
    redirect: payoutsrEgGAQS618Meta?.redirect,
    component: () => import("/workspace/pages/admin/payouts.vue").then(m => m.default || m),
    children: [
  {
    name: indexFrscT2sWUxMeta?.name ?? "admin-payouts-id",
    path: indexFrscT2sWUxMeta?.path ?? ":id()",
    meta: indexFrscT2sWUxMeta || {},
    alias: indexFrscT2sWUxMeta?.alias || [],
    redirect: indexFrscT2sWUxMeta?.redirect,
    component: () => import("/workspace/pages/admin/payouts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUchLdHrAqOMeta?.name ?? "admin-payouts",
    path: indexUchLdHrAqOMeta?.path ?? "",
    meta: indexUchLdHrAqOMeta || {},
    alias: indexUchLdHrAqOMeta?.alias || [],
    redirect: indexUchLdHrAqOMeta?.redirect,
    component: () => import("/workspace/pages/admin/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexfhR0bt52bzMeta?.name ?? "admin-policy",
    path: indexfhR0bt52bzMeta?.path ?? "policy",
    meta: indexfhR0bt52bzMeta || {},
    alias: indexfhR0bt52bzMeta?.alias || [],
    redirect: indexfhR0bt52bzMeta?.redirect,
    component: () => import("/workspace/pages/admin/policy/index.vue").then(m => m.default || m)
  },
  {
    name: index59MIkUB5IWMeta?.name ?? "admin-providers",
    path: index59MIkUB5IWMeta?.path ?? "providers",
    meta: index59MIkUB5IWMeta || {},
    alias: index59MIkUB5IWMeta?.alias || [],
    redirect: index59MIkUB5IWMeta?.redirect,
    component: () => import("/workspace/pages/admin/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexK4pDmDSPxYMeta?.name ?? "admin-users-fb-id",
    path: indexK4pDmDSPxYMeta?.path ?? "users-fb/:id()",
    meta: indexK4pDmDSPxYMeta || {},
    alias: indexK4pDmDSPxYMeta?.alias || [],
    redirect: indexK4pDmDSPxYMeta?.redirect,
    component: () => import("/workspace/pages/admin/users-fb/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index786oWL2DabMeta?.name ?? "admin-users-fb",
    path: index786oWL2DabMeta?.path ?? "users-fb",
    meta: index786oWL2DabMeta || {},
    alias: index786oWL2DabMeta?.alias || [],
    redirect: index786oWL2DabMeta?.redirect,
    component: () => import("/workspace/pages/admin/users-fb/index.vue").then(m => m.default || m)
  },
  {
    name: indexO9tuDy4tJxMeta?.name ?? "admin-users-id",
    path: indexO9tuDy4tJxMeta?.path ?? "users/:id()",
    meta: indexO9tuDy4tJxMeta || {},
    alias: indexO9tuDy4tJxMeta?.alias || [],
    redirect: indexO9tuDy4tJxMeta?.redirect,
    component: () => import("/workspace/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index7Q2JOnQTqrMeta?.name ?? "admin-users",
    path: index7Q2JOnQTqrMeta?.path ?? "users",
    meta: index7Q2JOnQTqrMeta || {},
    alias: index7Q2JOnQTqrMeta?.alias || [],
    redirect: index7Q2JOnQTqrMeta?.redirect,
    component: () => import("/workspace/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: action89Kg0P55P6Meta?.name ?? "auth-action",
    path: action89Kg0P55P6Meta?.path ?? "/auth/action",
    meta: action89Kg0P55P6Meta || {},
    alias: action89Kg0P55P6Meta?.alias || [],
    redirect: action89Kg0P55P6Meta?.redirect,
    component: () => import("/workspace/pages/auth/action.vue").then(m => m.default || m)
  },
  {
    name: loginipm5N7UCSPMeta?.name ?? "auth-login",
    path: loginipm5N7UCSPMeta?.path ?? "/auth/login",
    meta: loginipm5N7UCSPMeta || {},
    alias: loginipm5N7UCSPMeta?.alias || [],
    redirect: loginipm5N7UCSPMeta?.redirect,
    component: () => import("/workspace/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: confirmG7YZz1w9hpMeta?.name ?? "auth-reset-confirm",
    path: confirmG7YZz1w9hpMeta?.path ?? "/auth/reset/confirm",
    meta: confirmG7YZz1w9hpMeta || {},
    alias: confirmG7YZz1w9hpMeta?.alias || [],
    redirect: confirmG7YZz1w9hpMeta?.redirect,
    component: () => import("/workspace/pages/auth/reset/confirm.vue").then(m => m.default || m)
  },
  {
    name: indext6arxR9eOoMeta?.name ?? "auth-reset",
    path: indext6arxR9eOoMeta?.path ?? "/auth/reset",
    meta: indext6arxR9eOoMeta || {},
    alias: indext6arxR9eOoMeta?.alias || [],
    redirect: indext6arxR9eOoMeta?.redirect,
    component: () => import("/workspace/pages/auth/reset/index.vue").then(m => m.default || m)
  },
  {
    name: signupzo4AUvLtwkMeta?.name ?? "auth-signup",
    path: signupzo4AUvLtwkMeta?.path ?? "/auth/signup",
    meta: signupzo4AUvLtwkMeta || {},
    alias: signupzo4AUvLtwkMeta?.alias || [],
    redirect: signupzo4AUvLtwkMeta?.redirect,
    component: () => import("/workspace/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: indexmlSXcZRmpxMeta?.name ?? "blog-categorySlug-postSlug",
    path: indexmlSXcZRmpxMeta?.path ?? "/blog/:categorySlug()/:postSlug()",
    meta: indexmlSXcZRmpxMeta || {},
    alias: indexmlSXcZRmpxMeta?.alias || [],
    redirect: indexmlSXcZRmpxMeta?.redirect,
    component: () => import("/workspace/pages/blog/[categorySlug]/[postSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRgrKfU8NaHMeta?.name ?? "blog",
    path: indexRgrKfU8NaHMeta?.path ?? "/blog",
    meta: indexRgrKfU8NaHMeta || {},
    alias: indexRgrKfU8NaHMeta?.alias || [],
    redirect: indexRgrKfU8NaHMeta?.redirect,
    component: () => import("/workspace/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardKIhr9Z9Xg1Meta?.name ?? undefined,
    path: dashboardKIhr9Z9Xg1Meta?.path ?? "/dashboard",
    meta: dashboardKIhr9Z9Xg1Meta || {},
    alias: dashboardKIhr9Z9Xg1Meta?.alias || [],
    redirect: dashboardKIhr9Z9Xg1Meta?.redirect,
    component: () => import("/workspace/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: authorizations1I10MCutkhMeta?.name ?? undefined,
    path: authorizations1I10MCutkhMeta?.path ?? "authorizations",
    meta: authorizations1I10MCutkhMeta || {},
    alias: authorizations1I10MCutkhMeta?.alias || [],
    redirect: authorizations1I10MCutkhMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93T1HRjNqMITMeta?.name ?? undefined,
    path: _91id_93T1HRjNqMITMeta?.path ?? ":id()",
    meta: _91id_93T1HRjNqMITMeta || {},
    alias: _91id_93T1HRjNqMITMeta?.alias || [],
    redirect: _91id_93T1HRjNqMITMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: admissionorRKI4t9EYMeta?.name ?? "dashboard-authorizations-id-admission",
    path: admissionorRKI4t9EYMeta?.path ?? "admission",
    meta: admissionorRKI4t9EYMeta || {},
    alias: admissionorRKI4t9EYMeta?.alias || [],
    redirect: admissionorRKI4t9EYMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id]/admission.vue").then(m => m.default || m)
  },
  {
    name: costLOZ4KE25KOMeta?.name ?? "dashboard-authorizations-id-cost",
    path: costLOZ4KE25KOMeta?.path ?? "cost",
    meta: costLOZ4KE25KOMeta || {},
    alias: costLOZ4KE25KOMeta?.alias || [],
    redirect: costLOZ4KE25KOMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id]/cost.vue").then(m => m.default || m)
  },
  {
    name: diagnosisfN1dpRZUkTMeta?.name ?? "dashboard-authorizations-id-diagnosis",
    path: diagnosisfN1dpRZUkTMeta?.path ?? "diagnosis",
    meta: diagnosisfN1dpRZUkTMeta || {},
    alias: diagnosisfN1dpRZUkTMeta?.alias || [],
    redirect: diagnosisfN1dpRZUkTMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id]/diagnosis.vue").then(m => m.default || m)
  },
  {
    name: documentsCrKZ3II44HMeta?.name ?? "dashboard-authorizations-id-documents",
    path: documentsCrKZ3II44HMeta?.path ?? "documents",
    meta: documentsCrKZ3II44HMeta || {},
    alias: documentsCrKZ3II44HMeta?.alias || [],
    redirect: documentsCrKZ3II44HMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id]/documents.vue").then(m => m.default || m)
  },
  {
    name: indexLkOYDqDSNJMeta?.name ?? "dashboard-authorizations-id",
    path: indexLkOYDqDSNJMeta?.path ?? "",
    meta: indexLkOYDqDSNJMeta || {},
    alias: indexLkOYDqDSNJMeta?.alias || [],
    redirect: indexLkOYDqDSNJMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: teamzLQrSFuPvqMeta?.name ?? "dashboard-authorizations-id-team",
    path: teamzLQrSFuPvqMeta?.path ?? "team",
    meta: teamzLQrSFuPvqMeta || {},
    alias: teamzLQrSFuPvqMeta?.alias || [],
    redirect: teamzLQrSFuPvqMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id]/team.vue").then(m => m.default || m)
  },
  {
    name: treatmentuGDUxup8pUMeta?.name ?? "dashboard-authorizations-id-treatment",
    path: treatmentuGDUxup8pUMeta?.path ?? "treatment",
    meta: treatmentuGDUxup8pUMeta || {},
    alias: treatmentuGDUxup8pUMeta?.alias || [],
    redirect: treatmentuGDUxup8pUMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/[id]/treatment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexUekENRwKmWMeta?.name ?? "dashboard-authorizations",
    path: indexUekENRwKmWMeta?.path ?? "",
    meta: indexUekENRwKmWMeta || {},
    alias: indexUekENRwKmWMeta?.alias || [],
    redirect: indexUekENRwKmWMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/authorizations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: claims8UzpXkD7zNMeta?.name ?? "dashboard-claims",
    path: claims8UzpXkD7zNMeta?.path ?? "claims",
    meta: claims8UzpXkD7zNMeta || {},
    alias: claims8UzpXkD7zNMeta?.alias || [],
    redirect: claims8UzpXkD7zNMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93WWB9ZP9zmLMeta?.name ?? "dashboard-claims-id",
    path: _91id_93WWB9ZP9zmLMeta?.path ?? ":id()",
    meta: _91id_93WWB9ZP9zmLMeta || {},
    alias: _91id_93WWB9ZP9zmLMeta?.alias || [],
    redirect: _91id_93WWB9ZP9zmLMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: clientsEseddXbLLRMeta?.name ?? undefined,
    path: clientsEseddXbLLRMeta?.path ?? "clients",
    meta: clientsEseddXbLLRMeta || {},
    alias: clientsEseddXbLLRMeta?.alias || [],
    redirect: clientsEseddXbLLRMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/clients.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xnRmmsZxEnMeta?.name ?? "dashboard-clients-id",
    path: _91id_93xnRmmsZxEnMeta?.path ?? ":id()",
    meta: _91id_93xnRmmsZxEnMeta || {},
    alias: _91id_93xnRmmsZxEnMeta?.alias || [],
    redirect: _91id_93xnRmmsZxEnMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/clients/[id].vue").then(m => m.default || m)
  },
  {
    name: indexROEunKH4ApMeta?.name ?? "dashboard-clients",
    path: indexROEunKH4ApMeta?.path ?? "",
    meta: indexROEunKH4ApMeta || {},
    alias: indexROEunKH4ApMeta?.alias || [],
    redirect: indexROEunKH4ApMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/clients/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2lrBtCOh90Meta?.name ?? "dashboard",
    path: index2lrBtCOh90Meta?.path ?? "",
    meta: index2lrBtCOh90Meta || {},
    alias: index2lrBtCOh90Meta?.alias || [],
    redirect: index2lrBtCOh90Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: payouts3nVadYxOwoMeta?.name ?? undefined,
    path: payouts3nVadYxOwoMeta?.path ?? "payouts",
    meta: payouts3nVadYxOwoMeta || {},
    alias: payouts3nVadYxOwoMeta?.alias || [],
    redirect: payouts3nVadYxOwoMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payouts.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_9304LiK5YMTVMeta?.name ?? "dashboard-payouts-id",
    path: _91id_9304LiK5YMTVMeta?.path ?? ":id()",
    meta: _91id_9304LiK5YMTVMeta || {},
    alias: _91id_9304LiK5YMTVMeta?.alias || [],
    redirect: _91id_9304LiK5YMTVMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payouts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpNhBYRpF8XMeta?.name ?? "dashboard-payouts",
    path: indexpNhBYRpF8XMeta?.path ?? "",
    meta: indexpNhBYRpF8XMeta || {},
    alias: indexpNhBYRpF8XMeta?.alias || [],
    redirect: indexpNhBYRpF8XMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsgJ3MGvfJFXMeta?.name ?? undefined,
    path: settingsgJ3MGvfJFXMeta?.path ?? "settings",
    meta: settingsgJ3MGvfJFXMeta || {},
    alias: settingsgJ3MGvfJFXMeta?.alias || [],
    redirect: settingsgJ3MGvfJFXMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/settings.vue").then(m => m.default || m),
    children: [
  {
    name: indexnPOa0M2XREMeta?.name ?? "dashboard-settings",
    path: indexnPOa0M2XREMeta?.path ?? "",
    meta: indexnPOa0M2XREMeta || {},
    alias: indexnPOa0M2XREMeta?.alias || [],
    redirect: indexnPOa0M2XREMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/settings/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91city_id_932jux7r4bRsMeta?.name ?? "find-care-city_id",
    path: _91city_id_932jux7r4bRsMeta?.path ?? "/find-care/:city_id()",
    meta: _91city_id_932jux7r4bRsMeta || {},
    alias: _91city_id_932jux7r4bRsMeta?.alias || [],
    redirect: _91city_id_932jux7r4bRsMeta?.redirect,
    component: () => import("/workspace/pages/find-care/[city_id].vue").then(m => m.default || m)
  },
  {
    name: indexLI6LpVIusSMeta?.name ?? "for-patients",
    path: indexLI6LpVIusSMeta?.path ?? "/for-patients",
    meta: indexLI6LpVIusSMeta || {},
    alias: indexLI6LpVIusSMeta?.alias || [],
    redirect: indexLI6LpVIusSMeta?.redirect,
    component: () => import("/workspace/pages/for-patients/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtUpaPOwALMeta?.name ?? "for-providers",
    path: indexxtUpaPOwALMeta?.path ?? "/for-providers",
    meta: indexxtUpaPOwALMeta || {},
    alias: indexxtUpaPOwALMeta?.alias || [],
    redirect: indexxtUpaPOwALMeta?.redirect,
    component: () => import("/workspace/pages/for-providers/index.vue").then(m => m.default || m)
  },
  {
    name: homehESkjZ67H4Meta?.name ?? undefined,
    path: homehESkjZ67H4Meta?.path ?? "/home",
    meta: homehESkjZ67H4Meta || {},
    alias: homehESkjZ67H4Meta?.alias || [],
    redirect: homehESkjZ67H4Meta?.redirect,
    component: () => import("/workspace/pages/home.vue").then(m => m.default || m),
    children: [
  {
    name: indexw2hMVXqgujMeta?.name ?? "home-billing",
    path: indexw2hMVXqgujMeta?.path ?? "billing",
    meta: indexw2hMVXqgujMeta || {},
    alias: indexw2hMVXqgujMeta?.alias || [],
    redirect: indexw2hMVXqgujMeta?.redirect,
    component: () => import("/workspace/pages/home/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexdE734hEblTMeta?.name ?? "home-claims",
    path: indexdE734hEblTMeta?.path ?? "claims",
    meta: indexdE734hEblTMeta || {},
    alias: indexdE734hEblTMeta?.alias || [],
    redirect: indexdE734hEblTMeta?.redirect,
    component: () => import("/workspace/pages/home/claims/index.vue").then(m => m.default || m)
  },
  {
    name: indexMeYG1zOMoTMeta?.name ?? "home",
    path: indexMeYG1zOMoTMeta?.path ?? "",
    meta: indexMeYG1zOMoTMeta || {},
    alias: indexMeYG1zOMoTMeta?.alias || [],
    redirect: indexMeYG1zOMoTMeta?.redirect,
    component: () => import("/workspace/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: index9dcnwbPhttMeta?.name ?? "home-insurance",
    path: index9dcnwbPhttMeta?.path ?? "insurance",
    meta: index9dcnwbPhttMeta || {},
    alias: index9dcnwbPhttMeta?.alias || [],
    redirect: index9dcnwbPhttMeta?.redirect,
    component: () => import("/workspace/pages/home/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexROv3KMKUbeMeta?.name ?? "home-providers",
    path: indexROv3KMKUbeMeta?.path ?? "providers",
    meta: indexROv3KMKUbeMeta || {},
    alias: indexROv3KMKUbeMeta?.alias || [],
    redirect: indexROv3KMKUbeMeta?.redirect,
    component: () => import("/workspace/pages/home/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexaV1Sgoym5rMeta?.name ?? "home-settings",
    path: indexaV1Sgoym5rMeta?.path ?? "settings",
    meta: indexaV1Sgoym5rMeta || {},
    alias: indexaV1Sgoym5rMeta?.alias || [],
    redirect: indexaV1Sgoym5rMeta?.redirect,
    component: () => import("/workspace/pages/home/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loadingYin3E062Q1Meta?.name ?? "loading",
    path: loadingYin3E062Q1Meta?.path ?? "/loading",
    meta: loadingYin3E062Q1Meta || {},
    alias: loadingYin3E062Q1Meta?.alias || [],
    redirect: loadingYin3E062Q1Meta?.redirect,
    component: () => import("/workspace/pages/loading.vue").then(m => m.default || m)
  },
  {
    name: indexdEfuMmlaquMeta?.name ?? "login-verify",
    path: indexdEfuMmlaquMeta?.path ?? "/login-verify",
    meta: indexdEfuMmlaquMeta || {},
    alias: indexdEfuMmlaquMeta?.alias || [],
    redirect: indexdEfuMmlaquMeta?.redirect,
    component: () => import("/workspace/pages/login-verify/index.vue").then(m => m.default || m)
  },
  {
    name: indextTkPG1jUYQMeta?.name ?? "login",
    path: indextTkPG1jUYQMeta?.path ?? "/login",
    meta: indextTkPG1jUYQMeta || {},
    alias: indextTkPG1jUYQMeta?.alias || [],
    redirect: indextTkPG1jUYQMeta?.redirect,
    component: () => import("/workspace/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: onboard8CtHldbEa1Meta?.name ?? undefined,
    path: onboard8CtHldbEa1Meta?.path ?? "/onboard",
    meta: onboard8CtHldbEa1Meta || {},
    alias: onboard8CtHldbEa1Meta?.alias || [],
    redirect: onboard8CtHldbEa1Meta?.redirect,
    component: () => import("/workspace/pages/onboard.vue").then(m => m.default || m),
    children: [
  {
    name: _91partner_slug_93wEKnrLzytPMeta?.name ?? "onboard-partner_slug",
    path: _91partner_slug_93wEKnrLzytPMeta?.path ?? ":partner_slug()",
    meta: _91partner_slug_93wEKnrLzytPMeta || {},
    alias: _91partner_slug_93wEKnrLzytPMeta?.alias || [],
    redirect: _91partner_slug_93wEKnrLzytPMeta?.redirect,
    component: () => import("/workspace/pages/onboard/[partner_slug].vue").then(m => m.default || m)
  },
  {
    name: index9bLc195l0FMeta?.name ?? "onboard-address",
    path: index9bLc195l0FMeta?.path ?? "address",
    meta: index9bLc195l0FMeta || {},
    alias: index9bLc195l0FMeta?.alias || [],
    redirect: index9bLc195l0FMeta?.redirect,
    component: () => import("/workspace/pages/onboard/address/index.vue").then(m => m.default || m)
  },
  {
    name: indexqaRTieiQTAMeta?.name ?? "onboard-billing",
    path: indexqaRTieiQTAMeta?.path ?? "billing",
    meta: indexqaRTieiQTAMeta || {},
    alias: indexqaRTieiQTAMeta?.alias || [],
    redirect: indexqaRTieiQTAMeta?.redirect,
    component: () => import("/workspace/pages/onboard/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexep2FVeuO1hMeta?.name ?? "onboard-card-upload",
    path: indexep2FVeuO1hMeta?.path ?? "card-upload",
    meta: indexep2FVeuO1hMeta || {},
    alias: indexep2FVeuO1hMeta?.alias || [],
    redirect: indexep2FVeuO1hMeta?.redirect,
    component: () => import("/workspace/pages/onboard/card-upload/index.vue").then(m => m.default || m)
  },
  {
    name: index7pvcQ4WbaeMeta?.name ?? "onboard-clients",
    path: index7pvcQ4WbaeMeta?.path ?? "clients",
    meta: index7pvcQ4WbaeMeta || {},
    alias: index7pvcQ4WbaeMeta?.alias || [],
    redirect: index7pvcQ4WbaeMeta?.redirect,
    component: () => import("/workspace/pages/onboard/clients/index.vue").then(m => m.default || m)
  },
  {
    name: indexekDEk6fbD7Meta?.name ?? "onboard-eligibility",
    path: indexekDEk6fbD7Meta?.path ?? "eligibility",
    meta: indexekDEk6fbD7Meta || {},
    alias: indexekDEk6fbD7Meta?.alias || [],
    redirect: indexekDEk6fbD7Meta?.redirect,
    component: () => import("/workspace/pages/onboard/eligibility/index.vue").then(m => m.default || m)
  },
  {
    name: indexzAVvhj0a33Meta?.name ?? "onboard",
    path: indexzAVvhj0a33Meta?.path ?? "",
    meta: indexzAVvhj0a33Meta || {},
    alias: indexzAVvhj0a33Meta?.alias || [],
    redirect: indexzAVvhj0a33Meta?.redirect,
    component: () => import("/workspace/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexk4RQNAn1A2Meta?.name ?? "onboard-insurance",
    path: indexk4RQNAn1A2Meta?.path ?? "insurance",
    meta: indexk4RQNAn1A2Meta || {},
    alias: indexk4RQNAn1A2Meta?.alias || [],
    redirect: indexk4RQNAn1A2Meta?.redirect,
    component: () => import("/workspace/pages/onboard/insurance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0dcsGmFZa9Meta?.name ?? "print-invoice-id",
    path: index0dcsGmFZa9Meta?.path ?? "/print/invoice/:id()",
    meta: index0dcsGmFZa9Meta || {},
    alias: index0dcsGmFZa9Meta?.alias || [],
    redirect: index0dcsGmFZa9Meta?.redirect,
    component: () => import("/workspace/pages/print/invoice/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: bank_45info0vJ72NeQEuMeta?.name ?? "print-partner-partner_id-bank-info",
    path: bank_45info0vJ72NeQEuMeta?.path ?? "/print/partner/:partner_id()/bank-info",
    meta: bank_45info0vJ72NeQEuMeta || {},
    alias: bank_45info0vJ72NeQEuMeta?.alias || [],
    redirect: bank_45info0vJ72NeQEuMeta?.redirect,
    component: () => import("/workspace/pages/print/partner/[partner_id]/bank-info.vue").then(m => m.default || m)
  },
  {
    name: index21M5iF70M6Meta?.name ?? "privacy-policy",
    path: index21M5iF70M6Meta?.path ?? "/privacy-policy",
    meta: index21M5iF70M6Meta || {},
    alias: index21M5iF70M6Meta?.alias || [],
    redirect: index21M5iF70M6Meta?.redirect,
    component: () => import("/workspace/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexcPdLjFv6ymMeta?.name ?? "providers-slug",
    path: indexcPdLjFv6ymMeta?.path ?? "/providers/:slug()",
    meta: indexcPdLjFv6ymMeta || {},
    alias: indexcPdLjFv6ymMeta?.alias || [],
    redirect: indexcPdLjFv6ymMeta?.redirect,
    component: () => import("/workspace/pages/providers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: scribeLnBEMA1wUiMeta?.name ?? undefined,
    path: scribeLnBEMA1wUiMeta?.path ?? "/scribe",
    meta: scribeLnBEMA1wUiMeta || {},
    alias: scribeLnBEMA1wUiMeta?.alias || [],
    redirect: scribeLnBEMA1wUiMeta?.redirect,
    component: () => import("/workspace/pages/scribe.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93kUyEJHJhkyMeta?.name ?? "scribe-id",
    path: _91id_93kUyEJHJhkyMeta?.path ?? ":id()",
    meta: _91id_93kUyEJHJhkyMeta || {},
    alias: _91id_93kUyEJHJhkyMeta?.alias || [],
    redirect: _91id_93kUyEJHJhkyMeta?.redirect,
    component: () => import("/workspace/pages/scribe/[id].vue").then(m => m.default || m)
  },
  {
    name: _91doc_id_93HHCjgg8ZKiMeta?.name ?? "scribe-doc-doc_id",
    path: _91doc_id_93HHCjgg8ZKiMeta?.path ?? "doc/:doc_id()",
    meta: _91doc_id_93HHCjgg8ZKiMeta || {},
    alias: _91doc_id_93HHCjgg8ZKiMeta?.alias || [],
    redirect: _91doc_id_93HHCjgg8ZKiMeta?.redirect,
    component: () => import("/workspace/pages/scribe/doc/[doc_id].vue").then(m => m.default || m)
  },
  {
    name: indexItfVSR6KgoMeta?.name ?? "scribe",
    path: indexItfVSR6KgoMeta?.path ?? "",
    meta: indexItfVSR6KgoMeta || {},
    alias: indexItfVSR6KgoMeta?.alias || [],
    redirect: indexItfVSR6KgoMeta?.redirect,
    component: () => import("/workspace/pages/scribe/index.vue").then(m => m.default || m)
  },
  {
    name: indexAzVYbIFZwfMeta?.name ?? "scribe-settings",
    path: indexAzVYbIFZwfMeta?.path ?? "settings",
    meta: indexAzVYbIFZwfMeta || {},
    alias: indexAzVYbIFZwfMeta?.alias || [],
    redirect: indexAzVYbIFZwfMeta?.redirect,
    component: () => import("/workspace/pages/scribe/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index9xmF4TLFhBMeta?.name ?? "terms-of-use",
    path: index9xmF4TLFhBMeta?.path ?? "/terms-of-use",
    meta: index9xmF4TLFhBMeta || {},
    alias: index9xmF4TLFhBMeta?.alias || [],
    redirect: index9xmF4TLFhBMeta?.redirect,
    component: () => import("/workspace/pages/terms-of-use/index.vue").then(m => m.default || m)
  }
]