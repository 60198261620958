// stores/auth/access.ts - What the user can do
import { defineStore } from "pinia";
import type { UserRole, AppFeature } from "~/types/auth";
import { ROLE_FEATURES } from "~/types/auth";

export interface FirebaseClaims {
    roles?: UserRole[];
    access?: Record<string, boolean>;
    permissions?: {
        admin_id?: string;
        partner_id?: string;
        account_id?: string | null;
        provider_id?: string | null;
        sb_user_id?: string;
    };
    exp?: number;
    [key: string]: any;
}

export const useAccessStore = defineStore("access", () => {
    // Core state - store claims directly from Firebase token
    const claims = ref<FirebaseClaims>({});

    // Computed properties derived from claims
    const userRoles = computed<UserRole[]>(() => {
        return Array.isArray(claims.value?.roles) ? claims.value.roles : [];
    });

    const customAccess = computed<Record<string, boolean>>(() => {
        return claims.value?.access || {};
    });

    // Computed properties for user permissions
    const userPermissions = computed(() => {
        return claims.value?.permissions || {};
    });

    // Specific permission IDs
    const userId = computed(() => userPermissions.value?.sb_user_id || null);
    const partnerId = computed(() => userPermissions.value?.partner_id || null);
    const accountId = computed(() => userPermissions.value?.account_id || null);
    const providerId = computed(
        () => userPermissions.value?.provider_id || null
    );

    const adminId = computed(() => userPermissions.value?.admin_id || null);

    // Check if user has specific permissions
    const hasPartnerAccess = computed(() => !!partnerId.value);
    const hasAccountAccess = computed(() => !!accountId.value);
    const hasProviderAccess = computed(() => !!providerId.value);
    const hasAdminAccess = computed(() => !!adminId.value);

    // Role-based helpers
    const isAdmin = computed(
        () => userRoles.value.includes("admin") || !!adminId.value
    );
    const isClient = computed(
        () => userRoles.value.includes("client") || !!accountId.value
    );
    const isPartner = computed(
        () => userRoles.value.includes("partner") || !!partnerId.value
    );

    // Get identity store for authentication check
    const identityStore = useIdentityStore();
    const isAuthenticated = computed(() => identityStore.isAuthenticated);

    // Features derived from roles
    const userFeatures = computed<AppFeature[]>(() => {
        if (!isAuthenticated.value) return [];

        const features = new Set<AppFeature>();
        userRoles.value.forEach((role) => {
            if (ROLE_FEATURES[role]) {
                ROLE_FEATURES[role].forEach((feature) => features.add(feature));
            }
        });

        return Array.from(features);
    });

    // Set claims directly from Firebase token
    function setClaims(tokenClaims: FirebaseClaims) {
        claims.value = tokenClaims || {};
    }

    // Set permissions specifically
    function setPermissions(permissions: FirebaseClaims["permissions"]) {
        if (!claims.value) {
            claims.value = {};
        }
        claims.value.permissions = permissions;
    }

    // Set roles specifically
    function setRoles(roles: UserRole[]) {
        if (!claims.value) {
            claims.value = {};
        }
        claims.value.roles = roles;
    }

    // Check if user has a specific role
    function hasRole(role: UserRole): boolean {
        return userRoles.value.includes(role);
    }

    // Check if user has access to a feature
    function hasFeatureAccess(feature: AppFeature): boolean {
        // Not authenticated = no access
        if (!isAuthenticated.value) return false;

        // First check custom overrides
        if (customAccess.value[`feature:${feature}`] === false) return false;
        if (customAccess.value[`feature:${feature}`] === true) return true;

        // Then check role-based features
        return userFeatures.value.includes(feature);
    }

    // Utility for contextual access (entity-specific)
    function hasContextualAccess(entity: string, action: string): boolean {
        // Not authenticated = no access
        if (!isAuthenticated.value) return false;

        const accessKey = `${entity}:${action}`;

        // Check custom access overrides
        if (customAccess.value[accessKey] === false) return false;
        if (customAccess.value[accessKey] === true) return true;

        // Default access is typically role-based
        if (action === "read") {
            // Most roles can read
            return true;
        }

        if (action === "write" || action === "update" || action === "delete") {
            // Only admin can write/update/delete by default
            return isAdmin.value;
        }

        return false;
    }

    // Reset state on logout
    function reset() {
        claims.value = {};
    }

    return {
        // State
        claims,

        // Computed
        userRoles,
        userFeatures,
        customAccess,
        userPermissions,
        isAdmin,
        isClient,
        isPartner,

        // Permission IDs
        partnerId,
        accountId,
        providerId,
        userId,
        adminId,

        // Permission checks
        hasPartnerAccess,
        hasAccountAccess,
        hasProviderAccess,
        hasAdminAccess,

        // Methods
        setClaims,
        setPermissions,
        setRoles,
        hasRole,
        hasFeatureAccess,
        hasContextualAccess,
        reset,
    };
});
