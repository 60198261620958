// stores/auth/session.ts - How the user stays logged in
import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", () => {
    // Token management
    const tokenExpiresAt = ref<Date | null>(null);
    const lastRefreshed = ref<Date | null>(null);
    const refreshInProgress = ref<boolean>(false);
    const sessionTimeout = ref<number | null>(null);

    // Set token expiration from Firebase token
    function setTokenExpiration(expirationTime: string | number) {
        // Convert to Date if it's not already
        if (typeof expirationTime === "string") {
            tokenExpiresAt.value = new Date(expirationTime);
        } else if (typeof expirationTime === "number") {
            tokenExpiresAt.value = new Date(expirationTime * 1000); // Convert from seconds to milliseconds
        }

        // Schedule token refresh
        scheduleRefresh();
    }

    // Schedule a token refresh before expiration
    function scheduleRefresh() {
        // Clear any existing timeout
        if (sessionTimeout.value) {
            clearTimeout(sessionTimeout.value);
            sessionTimeout.value = null;
        }

        if (!tokenExpiresAt.value) return;

        const now = new Date();
        const expiresAt = tokenExpiresAt.value;

        // Refresh 5 minutes before expiration
        const refreshTime = expiresAt.getTime() - now.getTime() - 5 * 60 * 1000;

        if (refreshTime > 0) {
            sessionTimeout.value = window.setTimeout(() => {
                refreshToken();
            }, refreshTime);
        } else {
            // Token is already close to expiration, refresh now
            refreshToken();
        }
    }

    // Refresh the token
    async function refreshToken() {
        if (refreshInProgress.value) return;

        refreshInProgress.value = true;

        try {
            const { getFirebaseIdToken } = useFirebaseAuth();
            await getFirebaseIdToken(true); // Force refresh

            lastRefreshed.value = new Date();
        } catch (error) {
            console.error("Failed to refresh token:", error);
        } finally {
            refreshInProgress.value = false;
        }
    }

    // Reset on logout
    function reset() {
        // Clear timeout if one exists
        if (sessionTimeout.value) {
            clearTimeout(sessionTimeout.value);
            sessionTimeout.value = null;
        }

        tokenExpiresAt.value = null;
        lastRefreshed.value = null;
        refreshInProgress.value = false;
    }

    return {
        // State
        tokenExpiresAt,
        lastRefreshed,
        refreshInProgress,

        // Methods
        setTokenExpiration,
        refreshToken,
        reset,
    };
});
